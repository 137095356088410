<template>
  <div class="onceImgScreen">
    <div class="el-carousel">
      <p class="el-carousel__arrow el-carousel__arrow--left" @click="leftClick">
        <el-icon>
          <ArrowLeftBold/>
        </el-icon>
      </p>
      <p class="el-carousel__arrow el-carousel__arrow--right" @click="rightClick">
        <el-icon>
          <ArrowRightBold/>
        </el-icon>
      </p>
      <OnceItem1 v-if="onePicAddress==='OnceItem1'"/>
      <OnceItem2 v-if="onePicAddress==='OnceItem2'"/>
      <!--得力-->
      <DeliOnceImg1 v-if="onePicAddress==='DeliOnceImg1'"/>
      <DeliOnceImg2 v-if="onePicAddress==='DeliOnceImg2'"/>
      <DeliOnceImg3 v-if="onePicAddress==='DeliOnceImg3'"/>
      <DeliOnceImg4 v-if="onePicAddress==='DeliOnceImg4'"/>
      <DeliOnceImg5 v-if="onePicAddress==='DeliOnceImg5'"/>
      <DeliOnceImg6 v-if="onePicAddress==='DeliOnceImg6'"/>
      <DeliOnceImg7 v-if="onePicAddress==='DeliOnceImg7'"/>
      <DeliOnceImg8 v-if="onePicAddress==='DeliOnceImg8'"/>
      <DeliOnceImg9 v-if="onePicAddress==='DeliOnceImg9'"/>
      <DeliOnceImg10 v-if="onePicAddress==='DeliOnceImg10'"/>
    </div>
    <el-icon class="close" @click="backClick">
      <CloseBold/>
    </el-icon>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {ArrowLeftBold, ArrowRightBold, CloseBold} from '@element-plus/icons-vue'
import {markRaw, onMounted, reactive, ref, shallowRef, watch} from "vue";
import OnceItem1 from "@/pages/onceImg/onceItem/onceItem1";
import OnceItem2 from "@/pages/onceImg/onceItem/onceItem2";
import {getOnceImgDataApi} from "@/api/modules/onceImg";
import qs from "qs";
import {ElMessage} from "element-plus";
import router from "@/router";

// 得力一次图
import DeliOnceImg1 from './onceItem/deli/deliOnceImg1'
import DeliOnceImg2 from './onceItem/deli/deliOnceImg2'
import DeliOnceImg3 from './onceItem/deli/deliOnceImg3'
import DeliOnceImg4 from './onceItem/deli/deliOnceImg4'
import DeliOnceImg5 from './onceItem/deli/deliOnceImg5'
import DeliOnceImg6 from './onceItem/deli/deliOnceImg6'
import DeliOnceImg7 from './onceItem/deli/deliOnceImg7'
import DeliOnceImg8 from './onceItem/deli/deliOnceImg8'
import DeliOnceImg9 from './onceItem/deli/deliOnceImg9'
import DeliOnceImg10 from './onceItem/deli/deliOnceImg10'


const backClick = function () {
  router.push('/admin/onceImg')
}

const data = reactive([
  {
    name: 'A组件',
    com: markRaw(OnceItem1),
  },
  {
    name: 'B组件',
    com: markRaw(OnceItem2),
  },
])

let onePicAddress = ref()
let onePicAddressList = ref([])
let idx = ref()
// 获取一次图配置
const getOnceImgDataFun = function () {
  getOnceImgDataApi(qs.stringify({
    projectId: router.currentRoute.value.query.id
  })).then(res => {
    if (res.data.code === 0) {
      onePicAddressList.value = res.data.result.onePicAddressList
      onePicAddress.value = res.data.result.onePicAddress
      for (let i = 0; i < onePicAddressList.value.length; i++) {
        if (onePicAddressList.value[i] === res.data.result.onePicAddress) {
          idx.value = i
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


const leftClick = function () {
  if (idx.value !== 0) {
    idx.value = idx.value - 1
    onePicAddress.value = onePicAddressList.value[idx.value]
  }
}

const rightClick = function () {
  if (idx.value !== (onePicAddressList.value.length - 1)) {
    idx.value = idx.value + 1
    onePicAddress.value = onePicAddressList.value[idx.value]
  }
}

watch(idx, (newVal) => {
  if (newVal !== undefined) {
    if (newVal === 0) {
      document.querySelector('.el-carousel__arrow--left').style.cursor = 'no-drop'
      document.querySelector('.el-carousel__arrow--left .el-icon').style.cursor = 'no-drop'
    } else {
      document.querySelector('.el-carousel__arrow--left').style.cursor = 'pointer'
      document.querySelector('.el-carousel__arrow--left .el-icon').style.cursor = 'pointer'
    }
    if (newVal === (onePicAddressList.value.length - 1)) {
      document.querySelector('.el-carousel__arrow--right').style.cursor = 'no-drop'
      document.querySelector('.el-carousel__arrow--right .el-icon').style.cursor = 'no-drop'
    } else {
      document.querySelector('.el-carousel__arrow--right').style.cursor = 'pointer'
      document.querySelector('.el-carousel__arrow--right .el-icon').style.cursor = 'pointer'
    }
  }

}, {immediate: true, deep: true})

onMounted(() => {
  getOnceImgDataFun()
})


</script>

<style lang="less">
.onceImgScreen {
  width: calc(100vw - 440px);
  height: calc(100vh - 160px);
  background: #000;
  padding: 80px 220px;
  position: relative;

  .el-carousel {
    width: 100%;
    height: 100%;


    .el-carousel__arrow {
      display: block !important;
      width: 48px;
      height: 48px;
      background: #2F2F2F;
      color: #FFFFFF;
      line-height: 48px;
      border-radius: 6px;
      position: absolute;

      .el-icon {
        font-size: 20px;
        font-weight: 800;
        transform: translateY(6px);
      }
    }

    .el-carousel__arrow--left {
      left: -100px;
    }

    .el-carousel__arrow--right {
      right: -100px;
    }
  }

  .close {
    position: absolute;
    font-size: 18px;
    color: #fff;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
}
</style>
