<template>
  <div class="powerStationPage">
    <div class="title">
      <p>
        <span>光伏电站</span>
      </p>
    </div>
    <div class="content">
      <!--左侧内容-->
      <div class="leftBox">
        <div class="searchBox">
          <el-input v-model="searchVal" class="input-with-select" clearable placeholder="请输入">
            <template #append>
              <el-button :icon="Search" @click="searchClick(customerList,searchVal)"/>
            </template>
          </el-input>
        </div>
        <div class="treeBox">
          <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                   :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                   :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
            <template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
	              <span>{{ node.label }}</span>
                <el-tooltip content="新建分组" placement="top">
                  <img v-if="data.nodeType==2" alt=""
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/treeIcon1.png"
                       @click="addGroup(data)">
                </el-tooltip>
                <el-tooltip content="新建电站" placement="top">
                  <img v-if="data.nodeType==2" alt=""
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/treeIcon2.png"
                       style="width: 17px;height: 17px;"
                       @click="addPowerStation()">
                </el-tooltip>
                <el-tooltip content="编辑" placement="top">
                  <el-icon class="del" style="font-size: 15px" @click="editPowerStation(data.id,data)"><Edit/></el-icon>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-icon class="del" style="font-size: 15px" @click="delPowerStation(data.id)"><Delete/></el-icon>
                </el-tooltip>
              </span>
            </template>
          </el-tree>
        </div>
      </div>
      <!--右侧内容-->
      <div class="rightBox">
        <div style="height: 100%;overflow: auto">
          <TotalPowerStation v-if="isShowMap===1" :custerObj="custerObj" @isShowMapFun="isShowMapFun"/>
          <PowerStationMap v-if="isShowMap===2" :custerObj="custerObj" @isShowMapFun="isShowMapFun"/>
          <PowerStationItem v-if="isShowMap===3" :custerObj="custerObj" @isShowMapFun="isShowMapFun"/>
        </div>
      </div>
    </div>
    <!--新建、编辑电站弹窗-->
    <el-drawer v-model="drawerProject" :show-close="false" direction="rtl" size="30%">
      <template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerProject=false">
						<CloseBold/>
					</el-icon>
				</span>
        <span class="tltleClass">{{ projectFormData.id ? '编辑电站' : '新建电站' }}</span>
        <div class="operBox">
          <el-button class="resetButton" @click="drawerProject=false">取消</el-button>
          <el-button type="primary" @click="saveProjectSubmit">确认</el-button>
        </div>
      </template>
      <template #default>
        <div style="padding: 20px;">
          <el-form ref="projectFormRef" :model="projectFormData" :rules="projectFormRules" class="demo-ruleForm"
                   status-icon>
            <el-form-item label="电站名称" name="name" prop="name">
              <el-input v-model="projectFormData.name" placeholder="请输入电站名称"/>
            </el-form-item>
            <el-form-item label="电站地址" prop="address">
              <el-input v-model="projectFormData.address" placeholder="请输入项目地址"/>
            </el-form-item>
            <el-form-item label="地图选点" prop="">
              <el-input id="suggestId" v-model="nameValue" autocomplete="off" placeholder="请输入地址"
                        type="text"></el-input>
            </el-form-item>
            <div id="allmap"></div>
            <el-form-item label="经度" prop="longitude">
              <el-input v-model="projectFormData.longitude" placeholder="请输入经度" type="number"/>
            </el-form-item>
            <el-form-item label="纬度" prop="latitude">
              <el-input v-model="projectFormData.latitude" placeholder="请输入纬度" type="number"/>
            </el-form-item>
            <el-form-item label="联系人" prop="linkman">
              <el-input v-model="projectFormData.linkman" placeholder="请输入联系人"/>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="projectFormData.mobile" placeholder="请输入手机号码"/>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </el-drawer>
    <!--新建分组-->
    <el-dialog v-model="dialogVisible" title="新建分组" width="30%">
      <div style="display: flex;align-items: center">
        <span style="display: inline-block;width: 90px;">分组名称：</span>
        <el-input v-model="groupForm.name" placeholder="请输入分组名称"/>
      </div>
      <template #footer>
				<span class="dialog-footer">
          <el-button class="resetButton" @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="groupSubmit">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref, watch} from "vue"
import './powerStation.less'
import {delProjectListApi, getProjectListApi, saveProjectListApi} from "@/api/modules/project";
import {getStationTreeApi, addStationTreeList, delStationTreeList} from "@/api/modules/powerStation";
import {ElMessage, ElMessageBox} from "element-plus";
import {Search, Delete, CloseBold, Edit} from '@element-plus/icons-vue'
import TotalPowerStation from './totalPowerStation/totalPowerStation'
import PowerStationMap from './totalPowerStation/powerStationMap'
import PowerStationItem from './powerStationItem/powerStationItem'
import qs from "qs";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()

// 是否显示地图
let isShowMap = ref(1)
const isShowMapFun = function (val) {
  isShowMap.value = val
}


// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  defaultExpandKeys.value = []
  let res = tree(data, name)
  if (res) {
    treeRef.value.setCurrentKey(res.id)
    defaultExpandKeys.value.push(res.parentId)
    custerObj.value = res
  }
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)
//获取项目树级列表
const getStationListFun = async function () {
  await getStationTreeApi(qs.stringify({type: 1})).then(res => {
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
      //有无路由参数
      if (router.currentRoute.value.query.id) {
        defaultExpandKeys.value = [router.currentRoute.value.query.id]
      } else {
        if (window.localStorage.getItem('currentNodeKey5')) {
          defaultExpandKeys.value = [window.localStorage.getItem('currentNodeKey5')]
        } else {
          defaultExpandKeys.value = [customerList.value[0].id]
        }
      }
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        if (router.currentRoute.value.query.id) {
          currentNodeKey.value = router.currentRoute.value.query.id
          custerObj.value = JSON.parse(window.localStorage.getItem('project'))
        } else {
          if (window.localStorage.getItem('currentNodeKey5')) {
            currentNodeKey.value = window.localStorage.getItem('currentNodeKey5')
            custerObj.value = JSON.parse(window.localStorage.getItem('custerObj5'))
          } else {
            currentNodeKey.value = customerList.value[0].id
            custerObj.value = customerList.value[0]
          }
        }
        console.log(custerObj.value)
        nextTick(() => {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      nextTick(() => {
        treeRef.value.setCurrentKey(currentNodeKey.value)
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  window.localStorage.setItem('currentNodeKey5', currentNodeKey.value)
  window.localStorage.setItem('custerObj5', JSON.stringify(e))
  defaultExpandKeys.value.push(e.parentId)
}


// 新建分组----------------------------------------------------------------------------
let dialogVisible = ref(false)  //分组弹窗
let groupForm = ref({
  id: 0,
  type: 1,
  nodeType: 2,
  parentId: custerObj.value.id,
  name: ''
})
// 点击新建分组
const addGroup = function (num, data) {
  if (num) {
    groupForm.value = data
  } else {
    groupForm.value = {
      id: 0,
      type: 1,
      nodeType: 2,
      parentId: custerObj.value.id,
      name: ''
    }
  }
  dialogVisible.value = true
}
let addProjectName = ref('')
// 新建分组提交
const groupSubmit = function () {
  addStationTreeList(qs.stringify(groupForm.value)).then((res) => {
    if (res.data.code === 0) {
      dialogVisible.value = false
      getStationListFun()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch((err) => {
    console.log(err);
  })
}


// 新建电站
// 新建编辑电站----------------------------------------------------------------------------
// 新建编辑电站弹窗
let drawerProject = ref(false)
// 电站表单数据
let projectFormData = ref({
  id: 0,
  type: 1,
  parentId: custerObj.value.id,
  name: '',
  address: '',
  nodeType: 1,
  longitude: '',
  latitude: '',
  linkman: '',
  mobile: '',
})
// 电站表单规则
let projectFormRules = ref({
  name: [{
    required: true,
    message: '请输入项目名称',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '请输入项目地址',
    trigger: 'blur'
  }],
  mobile: [{
    message: '请输入有效的手机号码',
    pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
    trigger: 'blur'
  }]
})
// 新建编辑电站
const addPowerStation = function (num, data) {
  drawerProject.value = true
  if (num) {
    projectFormData.value = JSON.parse(JSON.stringify(data))
  } else {
    projectFormData.value = {
      id: 0,
      parentId: custerObj.value.id,
      name: '',
      type: 1,
      address: '',
      nodeType: 1,
      longitude: '',
      latitude: '',
      linkman: '',
      mobile: '',
    }
    if (data) {
      projectFormData.value.parentId = data.id
    } else {
      projectFormData.value.parentId = custerObj.value.id
    }
  }
  nextTick(() => {
    if (!projectFormData.value.longitude || !projectFormData.value.latitude) {
      mapAllFun(120.21551, 30.253082)
    } else {
      mapAllFun(projectFormData.value.longitude, projectFormData.value.latitude)
    }
  })
}
// 提交项目表单
let projectFormRef = ref()
const saveProjectSubmit = function () {
  // 提交表单
  if (projectFormRef) {
    projectFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        addStationTreeList(qs.stringify(projectFormData.value)).then(res => {
          if (res.data.code === 0) {
            getStationListFun()
            drawerProject.value = false
            // 给选中的信息赋值
            custerObj.value.id = res.data.result.id
            custerObj.value.parentId = res.data.result.parentId
            custerObj.value.name = res.data.result.name
            custerObj.value.type = res.data.result.type
            custerObj.value.address = res.data.result.address
            custerObj.value.longitude = res.data.result.longitude
            custerObj.value.latitude = res.data.result.latitude
            custerObj.value.linkman = res.data.result.linkman
            custerObj.value.mobile = res.data.result.mobile
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 编辑
const editPowerStation = function (num, data) {
  if (data.nodeType == 1) {
    addPowerStation(num, data)
  } else {
    addGroup(num, data)
  }
}
// 删除项目树
const delPowerStation = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除项目',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此项目，删除后数据不可恢复'),
    ])
  }).then(() => {
    delStationTreeList(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getStationListFun()
        currentNodeKey.value = undefined
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


//弹窗地图--------------------------------------------
let nameValue = ref()
let map = ref();
const mapAllFun = function (lng, lat) {
  map.value = new BMap.Map("allmap"); // 创建Map实例
  map.value.clearOverlays(); // 清除地图上所有覆盖物
  var point = new BMap.Point(lng, lat); // 创建点坐标，汉得公司的经纬度坐标
  map.value.centerAndZoom(point, 15); //初始化地图,设置中心点坐标，
  map.value.addOverlay(new BMap.Marker(point)); //添加标注
  map.value.enableScrollWheelZoom();
  var ac = new BMap.Autocomplete( //建立一个自动完成的对象
      {
        "input": "suggestId",
        "location": map.value
      })
  var myValue
  ac.addEventListener("onconfirm", function (e) { //鼠标点击下拉列表后的事件
    var _value = e.item.value;
    myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
    nameValue.value = myValue
    setPlace();
  });

  function setPlace() {
    map.value.clearOverlays(); //清除地图上所有覆盖物
    function myFun() {
      //console.log(local.getResults().getPoi(0).point)
      projectFormData.value.longitude = local.getResults().getPoi(0).point.lng
      projectFormData.value.latitude = local.getResults().getPoi(0).point.lat
      var userlocation = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
      map.value.centerAndZoom(userlocation, 15);
      map.value.addOverlay(new BMap.Marker(userlocation)); //添加标注
    }

    var local = new BMap.LocalSearch(map.value, { //智能搜索
      onSearchComplete: myFun
    });
    local.search(myValue);
  }

  //地图的点击
  map.value.addEventListener("click", function (e) {
    console.log(e); //经度、维度
    projectFormData.value.longitude = e.point.lng
    projectFormData.value.latitude = e.point.lat
    map.value.clearOverlays()
    var userlocation = e.point //获取第一个智能搜索的结果
    map.value.centerAndZoom(userlocation, 15);
    map.value.addOverlay(new BMap.Marker(userlocation)); //添加标注
  })
}


onMounted(() => {
  getStationListFun()
})

watch(custerObj, (newVal) => {
  if (isShowMap.value != 2) {
    if (newVal.nodeType == 1) {
      isShowMap.value = 3
    } else {
      isShowMap.value = 1
    }
  }
}, {immediate: true, deep: true})
</script>

<style lang="less">
#allmap {
  width: 100%;
  height: 200px;
  font-family: "微软雅黑";
  border: 1px solid green;
}

.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}

.tangram-suggestion {
  z-index: 99999;
}
</style>
