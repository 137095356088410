<template>
  <div class="searchPlan">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="router.push('/admin/dataSearch')">数据查询</span>
        <span> / </span>
        <span>数据查询管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        数据查询管理
      </p>
    </div>
    <!--列表-->
    <div class="content">
      <div class="contain">
        <!--新增查询-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增查询
        </el-button>
        <!--查询列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增查询方案</p>
            </el-empty>
          </template>
          <el-table-column label="查询名称" prop="queryName"></el-table-column>
          <el-table-column label="创建时间" prop="ct" sortable></el-table-column>
          <el-table-column label="创建人" prop="creator"></el-table-column>
          <el-table-column fixed="right" label="操作" width="240px">
            <template #default="scope">
              <div class="opacity">
                <span class="editClass btn" @click.prevent="openClick(scope.row.id,scope.row)">打开</span>
                <span v-if="scope.row.canCopyFlag" class="editClass btn"
                      @click.prevent="applicationPlanClick(scope.row.id,scope.row)">应用</span>
                <span class="editClass btn" @click.prevent="chartPlanClick(scope.row.id,scope.row)">图表</span>
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass btn delClass" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--新增编辑弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑查询':'新建查询'"
               width="60%">
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <el-form-item label="查询名称" prop="queryName" style="width: 33%;">
          <el-input v-model="formData.queryName" placeholder="请输入查询名称"></el-input>
        </el-form-item>
        <el-form-item label="数据间隔" prop="frequency" style="width: 33%;">
          <!--          <el-input v-model="formData.frequency" :disabled="true" placeholder="请输入采集频率" type="number">-->
          <!--            &lt;!&ndash;            <template #suffix>&ndash;&gt;-->
          <!--            &lt;!&ndash;              分钟&ndash;&gt;-->
          <!--            &lt;!&ndash;            </template>&ndash;&gt;-->
          <!--            <template #append>-->
          <!--              <el-select v-model="formData.timeUnit" style="width: 90px;">-->
          <!--                <el-option label="分钟" value="m"/>-->
          <!--                <el-option label="小时" value="h"/>-->
          <!--                <el-option label="天" value="d"/>-->
          <!--                <el-option label="月" value="n"/>-->
          <!--                <el-option label="年" value="y"/>-->
          <!--              </el-select>-->
          <!--            </template>-->
          <!--          </el-input>-->
          <el-select v-model="formData.timeUnit" placeholder="请选择数据间隔" style="width: 90px;">
            <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value" :value="item.key"/>
          </el-select>
        </el-form-item>
        <el-form-item label="展示方式" prop="chatType" style="width: 33%;">
          <el-select v-model="formData.chatType" placeholder="请选择展示方式">
            <el-option :value="1" label="折线图"></el-option>
            <el-option :value="0" label="柱状图"></el-option>
            <el-option :value="4" label="饼图"></el-option>
            <el-option :disabled="true" :value="2" label="面积图"></el-option>
            <el-option :disabled="true" :value="3" label="点图"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期期限" prop="timeFlag" style="width: 34%;">
          <el-select v-model="formData.timeFlag" clearable @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option :disabled="formData.chatType==4?false:true" label="瞬时" value="now"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询日期" prop="date" style="width: 50%">
          <el-date-picker v-model="formData.date" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="yom" style="width: 16%">
          <el-checkbox-group v-model="formData.yom" :max="1" :min="0"
                             style="display: inline-block;margin-left: 20px">
            <el-checkbox :label="1">同比</el-checkbox>
            <el-checkbox :label="2">环比</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div v-for="(item,index) in formData.itemList" v-if="formData.canEditEquipmentFlag==1" :key="index"
             class="rowItem">
          <el-form-item label="设备" name="item.deviceId">
            <!--            <el-select v-model="item.deviceId" placeholder="请选择设备"-->
            <!--                       @change="equipmentChange(index)">-->
            <!--              <el-option v-for="(item_d,index_d) in equipmentList" :key="index_d" :label="item_d.name"-->
            <!--                         :value="item_d.id"></el-option>-->
            <!--            </el-select>-->
            <el-tree-select
                ref="treeRef1"
                v-model="item.deviceId"
                :data="allTree"
                :props="props"
                :render-after-expand="false"
                :render-content="renderContent"
                filterable
                node-key="id"
                placeholder="请选择设备"
                style="height: auto!important;"
                @change="equipmentChange(index)"
            />
          </el-form-item>
          <el-form-item class="data" name="item.data">
            <el-select v-model="item.dictKeys" class="item" multiple placeholder="请选择指标" @change="dataChange(index)">
              <el-option v-for="(item_d,index_d) in dataList[index]" :key="index_d" :label="item_d.value"
                         :value="item_d.key"></el-option>
            </el-select>
          </el-form-item>

          <el-icon v-if="formData.itemList.length>1" class="delIcon" @click="delItemClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===formData.itemList.length-1" class="addIcon" @click="addItemClick">
            <CirclePlus/>
          </el-icon>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--创建图表弹窗-->
    <el-drawer v-model="drawerChart" :show-close="false" direction="rtl" size="580">
      <template #header>
        <span class="tltleClass">创建图表</span>
        <span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerChart=false">
						<CloseBold/>
					</el-icon>
				</span>
      </template>
      <template #footer>
        <div class="operBox">
          <el-button class="resetButton" @click="drawerChart=false">取消</el-button>
          <el-button type="primary" @click="saveProjectSubmit">确认</el-button>
        </div>
      </template>
      <template #default>
        <div style="padding: 20px;height: calc(100% - 40px)">
          <el-form ref="chartFormRef" :model="chartFormData" :rules="chartFormRules" class="demo-ruleForm"
                   status-icon>
            <el-form-item label="资产目录:" prop="projectId">
              <el-tree-select
                  ref="treeRef"
                  v-model="chartFormData.projectId"
                  :check-strictly="true"
                  :data="customerList"
                  :expand-on-click-node="false"
                  :props="{label:'name',children: 'subList'}"
                  filterable
                  node-key="id"
                  style="height: auto!important;"
                  @change="getQueryGroupListFun"
              />
            </el-form-item>
            <el-form-item label="分组:" name="name" prop="name">
              <el-select v-model="queryGroup" :teleported="false" allow-create class="planBox"
                         clearable filterable placeholder="请选择已有分组或新增分组">
                <el-option v-for="(item,index) in queryGroupList" :key="index" :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="transferRightBox">
            <div class="head">
              <p>已应用的设备</p>
            </div>
            <!--多设备操作-->
            <div class="transferContain">
              <div class="transferContainHead">
                <p>
                  <el-checkbox v-model="checkAll" @change="checkAllFun"></el-checkbox>
                </p>
                <p>序号</p>
                <p v-for="(item,index) in checkList[0].arr" :key="index">设备{{ index + 1 }}</p>
              </div>
              <div class="transferContainCont">
                <div v-for="(item,index) in checkList" :key="index" class="transferContainItem">
                  <p style="width: 40px;">
                    <el-checkbox v-model="item.check"></el-checkbox>
                  </p>
                  <p style="width: 60px">
                    <span>{{ index + 1 }}</span>
                  </p>
                  <p v-for="(item_t,index_t) in item.arr" :key="index_t"
                     :style="{width: checkListWidth[index_t+2]+'px'}">
                    <el-tooltip :content="item_t.value">
                      <span style="color: #165DFF">{{ item_t.value }}</span>
                    </el-tooltip>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref, watch} from "vue"
import './searchPlan.less'

import {CloseBold, ZoomIn, Plus, Back, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  delSearchListApi,
  editSearchListApi,
  getDataListApi,
  getEquipmentListApi, getIntervalListApi, getSearchDetailApi,
  getSearchListApi, getSearchListApiPag, getUseEquipmentApi,
  saveSearchListApi
} from "@/api/modules/dataSearch";
import moment from "moment";
import {getAllTreeApi} from "@/api/modules/strategy";
import {v4 as uuidv4} from "uuid";
import {addQueryGroupListApi, getProjectListApi, getQueryGroupListApi} from "@/api/modules/project";


let router = useRouter()
let route = useRouter()

// 返回
const goBack = function () {
  // router.push('/admin/dataSearch')
  router.go(-1)
}


// 方案列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getSearchListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getSearchListFun()
}
// 表格数据
let tableData = ref([])
// 获取表格数据
let loading = ref(false)
const getSearchListFun = function () {
  loading.value = true
  getSearchListApiPag(qs.stringify({
    type: 0,
    pageSize: pageSize.value,
    pageNumber: pageNumber.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)
// 设备列表
let equipmentList = ref([])
// 指标列表
let dataList = ref([])
// 获取设备列表
const getEquipmentListFun = function () {
  getEquipmentListApi().then(res => {
    if (res.data.code === 200) {
      equipmentList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取指标列表
const getDataListFun = function (index, id) {
  if (id) {
    getDataListApi(qs.stringify({id: id})).then(res => {
      if (res.data.code === 0) {
        dataList.value[index] = res.data.result
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 选择设备事件
let treeRef1 = ref()
const equipmentChange = function (index) {
  formData.value.itemList[index].dictKeys = []
  // getDataListFun(index)
  getDataListFun(index, treeRef1.value[index].getCurrentNode().id)
  formData.value.itemList[index].deviceId = treeRef1.value[index].getCurrentNode().id
}
// 选择指标事件
const dataChange = function (index) {
  if (formData.value.itemList[index].dictKeys.length > 5) {
    ElMessage({
      type: 'info',
      message: '最多选择5个指标'
    })
    formData.value.itemList[index].dictKeys.splice(-1)
  }
}
// 新增编辑表单数据
let formData = ref({
  queryName: '',
  queryType: 0,
  timeFlag: '',
  yom: [],
  date: [],
  startTime: '',
  endTime: '',
  frequency: 1,
  timeUnit: '1m',
  chatType: 1,
  canEditEquipmentFlag: 1,
  creator: window.localStorage.getItem('memberId'),
  itemList: [
    {
      deviceId: '',
      dictKeys: [],
    }
  ]
})
// 表单规则
let formRules = ref({
  queryName: [
    {required: true, message: '请输入查询方案名称', trigger: 'blur'},
  ],
  timeFlag: [
    {required: true, message: '请选择日期期限', trigger: 'blur'},
  ],
  date: [
    {required: true, message: '请选择时间', trigger: 'blur'},
  ]
})
// 日期快捷选择事件
const timeFlagSelect = function () {
  if (formData.value.timeFlag === '-1') {
    formData.value.date = []
  } else if (formData.value.timeFlag === '1H') {
    formData.value.date = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '12H') {
    formData.value.date = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1D') {
    formData.value.date = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '3D') {
    formData.value.date = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1W') {
    formData.value.date = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1M') {
    formData.value.date = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')]
  } else if (formData.value.timeFlag === '1Q') {
    formData.value.date = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value = {
    queryName: '',
    queryType: 0,
    timeFlag: '',
    yom: [],
    date: '',
    startTime: '',
    endTime: '',
    frequency: 1,
    timeUnit: '1m',
    chatType: 1,
    canEditEquipmentFlag: 1,
    creator: window.localStorage.getItem('memberId'),
    itemList: [
      {
        deviceId: '',
        dictKeys: [],
      }
    ]
  }
}
// 点击编辑
const editClick = function (id, row) {
  dialogFormVisible.value = true
  getSearchDetailApi(id).then(res => {
    if (res.data.code === 200) {
      formData.value = {
        id: id,
        queryName: row.queryName,
        queryType: 0,
        timeFlag: res.data.data.timeFlag,
        yom: res.data.data.yom ? [res.data.data.yom] : [],
        date: [res.data.data.startTime, res.data.data.endTime],
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        frequency: res.data.data.frequency,
        timeUnit: res.data.data.timeUnit,
        chatType: res.data.data.chatType,
        canEditEquipmentFlag: res.data.data.canEditEquipmentFlag,
        creator: window.localStorage.getItem('memberId'),
        itemList: res.data.data.deviceConditionList
      }
      for (let i = 0; i < formData.value.itemList.length; i++) {
        getDataListFun(i, formData.value.itemList[i].deviceId)
      }
      // 处理指标
      for (let i = 0; i < formData.value.itemList.length; i++) {
        formData.value.itemList[i].dictKeys = []
        for (let j = 0; j < formData.value.itemList[i].dictList.length; j++) {
          formData.value.itemList[i].dictKeys[j] = formData.value.itemList[i].dictList[j].key
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击增加设备
const addItemClick = function () {
  formData.value.itemList.push({
    deviceId: '',
    dictKeys: [],
  })
}
// 点击删除设备
const delItemClick = function (index) {
  formData.value.itemList.splice(index, 1)
  dataList.value.splice(index, 1)
}
// 提交
let fromRef = ref()
const save = function () {
  console.log(formData.value, 'qqq')
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let flag = formData.value.itemList.length > 0 ? true : false
        for (let i = 0; i < formData.value.itemList.length; i++) {
          if (formData.value.itemList[i].deviceId) {
            if (formData.value.itemList[i].dictKeys.length > 0) {
              // flag = true
            } else {
              flag = false
            }
          } else {
            flag = false
          }
        }
        if (flag) {
          formData.value.startTime = formData.value.date[0]
          formData.value.endTime = formData.value.date[1]
          if (formData.value.yom.length === 0) {
            formData.value.yom = 0
          } else {
            formData.value.yom = formData.value.yom[0]
          }
          // 处理指标
          for (let i = 0; i < formData.value.itemList.length; i++) {
            formData.value.itemList[i].dictJsonStr = []
            for (let j = 0; j < formData.value.itemList[i].dictKeys.length; j++) {
              for (let k = 0; k < dataList.value[i].length; k++) {
                if (formData.value.itemList[i].dictKeys[j] === dataList.value[i][k].key) {
                  formData.value.itemList[i].dictJsonStr[j] = dataList.value[i][k]
                }
              }
            }
            formData.value.itemList[i].dictJsonStr = JSON.stringify(formData.value.itemList[i].dictJsonStr)
            delete formData.value.itemList[i].dictKeys
            delete formData.value.itemList[i].dictIds
            delete formData.value.itemList[i].dictList
          }

          if (formData.value.chatType === 4) {
            formData.value.yom = 0
          }

          if (formData.value.id) {
            editSearchListApi(formData.value).then(res => {
              if (res.data.code === 200) {
                getSearchListFun()
                dialogFormVisible.value = false
              } else {
                ElMessage({
                  type: 'error',
                  message: res.data.message
                })
              }
            }).catch(err => {
              console.log(err);
            })
          } else {
            saveSearchListApi(formData.value).then(res => {
              if (res.data.code === 200) {
                getSearchListFun()
                dialogFormVisible.value = false
              } else {
                ElMessage({
                  type: 'error',
                  message: res.data.message
                })
              }
            }).catch(err => {
              console.log(err);
            })
          }
        } else {
          ElMessage({
            type: 'info',
            message: '请输入必填项'
          })
        }
      }
    })
  }
}


// 点击删除
const delClick = function (id, row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除查询方案',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此查询方案，删除后数据不可恢复'),
    ])
  }).then(() => {
    delSearchListApi(id).then(res => {
      if (res.data.code === 200) {
        getSearchListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}

// 日期快捷选择事件
const timeFlagSelect2 = function (timeFlag) {
  if (timeFlag === '-1') {
    return []
  } else if (timeFlag === '1H') {
    return [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '12H') {
    return [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1D') {
    return [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '3D') {
    return [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1W') {
    return [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === 'THIS_WEEK') {
    return [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1M') {
    return [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1Q') {
    return [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // return [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}

// 点击打开
const openClick = async function (id, row) {
  // 获取方案详情-------------------------
  await getSearchDetailApi(id).then(res => {
    if (res.data.code === 200) {
      // 处理图表查询条件数据
      let data = {
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        frequency: res.data.data.frequency,
        timeUnit: res.data.data.timeUnit,
        timeFlag: res.data.data.timeFlag,
        chatType: res.data.data.chatType,
        params: res.data.data.deviceConditionList,
        yom: res.data.data.yom
      }
      if (res.data.data.timeFlag !== '-1' && res.data.data.timeFlag !== 'now') {
        data.startTime = timeFlagSelect2(res.data.data.timeFlag)[0]
        data.endTime = timeFlagSelect2(res.data.data.timeFlag)[1]
      }
      // 计算同环比时间
      if (data.yom == 1) {
        data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      } else if (data.yom == 2) {
        if (data.timeFlag === '1M') {
          data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
        } else if (data.timeFlag === '1Q') {
          data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
          data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        } else {
          let timeLength = moment(data.endTime).diff(data.startTime, 'second')
          data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      for (let i = 0; i < data.params.length; i++) {
        data.params[i].dictKeys = []
        for (let j = 0; j < data.params[i].dictList.length; j++) {
          data.params[i].dictKeys.push(data.params[i].dictList[j].key)
        }
        delete data.params[i].dictList
        delete data.params[i].dictIds
      }

      // 处理表格查询条件
      let tableArr = []
      let pageData = []
      for (let i = 0; i < data.params.length; i++) {
        pageData.push({
          pageNum: 1,
          pageSize: 10
        })
        let dataArr = data
        dataArr.params = [data.params[i]]
        tableArr.push(dataArr)
      }

      // 点击跳转并传递查询条件
      let obj = {
        echartSearchData: data,
        tableSearchData: tableArr
      }
      let uid = uuidv4()
      window.localStorage.setItem(uid, JSON.stringify(obj));
      window.open(`#/homeSearchScreen?queryType=1&uid=${uid}`)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })

}


// 方案应用操作-----------------------------------------------------------------
const applicationPlanClick = function (id, row) {
  router.push({
    path: '/admin/applicationPlan',
    query: {
      id: id,
      name: row.queryName,
      type: 0
    }
  })
}


// 创建图表-------------------------------------------------------------------------
let drawerChart = ref(false)  //创建图表弹窗
let chartFormData = ref({
  projectId: ''
})  //图表弹窗的数据
let chartFormRules = ref({
  projectId: [{
    required: true,
    message: '请选择需要绑定的资产',
    trigger: 'blur'
  },]
})
// 点击创建图表
let chartIdx = ref()
const chartPlanClick = function (id, row) {
  getUseEquipmentFun(id)
  chartIdx.value = id
  drawerChart.value = true
}
// 获取分组列表
let queryGroup = ref('')
let queryGroupList = ref([])
const getQueryGroupListFun = function () {
  getQueryGroupListApi(qs.stringify({
    type: 2,
    projectId: chartFormData.value.projectId
  })).then(res => {
    if (res.data.code === 0) {
      queryGroupList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let checkList = ref([{}])  //设备方案已选设备
let checkListWidth = ref([])
// 获取应用设备初始化列表
const getUseEquipmentFun = function (id) {
  getUseEquipmentApi(qs.stringify({
    id: id,
  })).then(res => {
    if (res.data.code === 0) {
      checkList.value = []
      let list = JSON.parse(JSON.stringify(res.data.result))
      for (let i = 0; i < list.length; i++) {
        checkList.value.push({
          check: false,
          arr: []
        })
        for (let j = 0; j < list[i].length; j++) {
          checkList.value[i].arr.push(list[i][j])
        }
      }
      nextTick(() => {
        let arr1 = document.querySelectorAll('.transferContainHead p')
        checkListWidth.value = []
        for (let i = 0; i < arr1.length; i++) {
          checkListWidth.value.push(Number(arr1[i].offsetWidth) - 2)
        }
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 全选
let checkAll = ref(false)
let checkAllFun = function () {
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkAll.value) {
      checkList.value[i].check = true
    } else {
      checkList.value[i].check = false
    }
  }
}
// 创建图表提交
let chartFormRef = ref()
const saveProjectSubmit = function () {
  let list = []
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkList.value[i].check) {
      list[i] = []
      for (let j = 0; j < checkList.value[i].arr.length; j++) {
        list[i].push(checkList.value[i].arr[j].key)
      }
    }
  }
  let list1 = list.filter(element => element != null && element !== '' && element !== undefined)
  chartFormRef.value.validate((valid) => {
    //提交成功
    if (valid) {
      if (list1.length > 0 && chartFormData.value.projectId !== '' && chartIdx.value !== '') {
        addQueryGroupListApi(qs.stringify({
          type: 2,
          projectId: chartFormData.value.projectId,
          groupName: queryGroup.value,
          queryId: chartIdx.value,
          equipmentIdJson: JSON.stringify(list1)
        })).then(res => {
          if (res.data.code === 0) {
            drawerChart.value = false
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            })
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        ElMessage({
          type: 'info',
          message: '请输入必填项'
        })
      }
    }
  })

}


// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let treeRef = ref(null)
//获取项目树级列表
const getProjectListFun = async function () {
  await getProjectListApi().then(res => {
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 设备树---------------------------------------------------------------
// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 自定义树形结构的前面图标
const renderContent = (h, {node, data}) => {
  return h('div', [
    h('img', {
      src: data.isProject ? 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon5.png' : 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon4.png',
      style: {
        width: '16px',
        height: '16px',
        transform: 'translateY(3px)',
        marginRight: '5px'
      }
    }, ''),
    h('span', null, node.label)
  ])


}
// 树形结构数据
let allTree = ref()
let isAddBtn = ref(false) //判断是否是新增按钮进来
let intervalList = ref([])  //数据间隔列表
onMounted(() => {
  getProjectListFun()  //获取项目树
  getSearchListFun()
  getQueryGroupListFun()  //获取分组列表
  // getEquipmentListFun()

  // 获取所有设备树
  getAllTreeApi().then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })


  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })


  if (router.currentRoute.value.query.flag) {
    isAddBtn.value = true
    dialogFormVisible.value = true
  }
  if (router.currentRoute.value.query.id) {
    getUseEquipmentFun(router.currentRoute.value.query.id)
    chartIdx.value = router.currentRoute.value.query.id
    drawerChart.value = true
  }
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});
})


watch(dialogFormVisible, (newVal) => {
  if (!newVal) {
    isAddBtn.value = false
  }
})

</script>

<style lang="less" scoped>
</style>
