<template>
  <div class="powerStationSetting">
    <div class="title">
      <p>
        <span @click="goBack">光伏电站</span>
        <span> / </span>
        <span>配置</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        配置
      </p>
    </div>
    <div class="content">
      <p class="tit">发电量</p>
      <div class="contentItem">
        <div class="liItemBox">
          <div class="bgc">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem1.png">
            <p>累计发电量 kwh</p>
          </div>
          <p style="margin-left: 30px">=</p>
        </div>
        <div v-for="(item,index) in settingData.generateElectricity.data" :key="index"
             class="contItemBox">
          <div v-if="index!==0" class="symbol">
            <p
                :class="settingData.generateElectricity.formula.substring(2*index-1,2*index)==='+'?'activeSymbol':''"
                @click="symbolClick('generateElectricity',index,'+')">+</p>
            <p
                :class="settingData.generateElectricity.formula.substring(2*index-1,2*index)==='-'?'activeSymbol':''"
                @click="symbolClick('generateElectricity',index,'-')">-</p>
            <p
                :class="settingData.generateElectricity.formula.substring(2*index-1,2*index)==='×'||settingData.generateElectricity.formula.substring(2*index-1,2*index)==='*'?'activeSymbol':''"
                @click="symbolClick('generateElectricity',index,'*')">×</p>
            <p
                :class="settingData.generateElectricity.formula.substring(2*index-1,2*index)==='÷'||settingData.generateElectricity.formula.substring(2*index-1,2*index)==='/'?'activeSymbol':''"
                @click="symbolClick('generateElectricity',index,'/')">÷</p>
          </div>
          <div class="contItem">
            <el-select v-model="item.type" clearable placeholder="请选择参数类型">
              <el-option :value="1" label="指标"/>
              <el-option :value="0" label="数值"/>
            </el-select>
            <el-icon v-if="settingData.generateElectricity.data.length>1" class="delIcon"
                     @click="delControl('generateElectricity',index)">
              <CircleClose/>
            </el-icon>
            <div v-if="item.type!==''" class="contBox">
              <el-select v-show="item.type==1" ref="elSelectTable" v-model="item.typeName"
                         :filter-method="filterMethod" :popper-append-to-body="false"
                         class="select-unitName select-option-father" clearable filterable
                         popper-class="select_report_and_type">
                <el-option :value="dataItemList">
                  <el-tree
                      ref="treeRef"
                      :expand-on-click-node=true
                      :filter-node-method="filterNode"
                      :load="treeList"
                      :props="props"
                      highlight-current
                      lazy
                      @node-click="clickUnitTreeNodeChild($event,'generateElectricity',index)"
                  />
                </el-option>
              </el-select>
              <div class="center">
                <el-input v-if="item.type==0" v-model="item.val" class="val" placeholder="请输入"
                          type="number"
                />
                <span v-if="item.type==1&& item.deviceName">{{ item.deviceName }}</span>
                <!--                    <p v-if="item.type==1">{{ item.typeName }}</p>-->
              </div>
              <span v-if="item.type==1" class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div>
          <el-icon class="addIcon" @click="addControl('generateElectricity')">
            <CirclePlus/>
          </el-icon>
        </div>
      </div>
      <p class="tit">累计收益</p>
      <div class="contentItem">
        <div class="liItemBox">
          <div class="bgc">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem2.png">
            <p>累计发电量 kwh</p>
          </div>
          <p style="margin-left: 30px">=</p>
        </div>
        <div v-for="(item,index) in settingData.income.data" :key="index"
             class="contItemBox">
          <div v-if="index!==0" class="symbol">
            <p
                :class="settingData.income.formula.substring(2*index-1,2*index)==='+'?'activeSymbol':''"
                @click="symbolClick('income',index,'+')">+</p>
            <p
                :class="settingData.income.formula.substring(2*index-1,2*index)==='-'?'activeSymbol':''"
                @click="symbolClick('income',index,'-')">-</p>
            <p
                :class="settingData.income.formula.substring(2*index-1,2*index)==='×'||settingData.income.formula.substring(2*index-1,2*index)==='*'?'activeSymbol':''"
                @click="symbolClick('income',index,'*')">×</p>
            <p
                :class="settingData.income.formula.substring(2*index-1,2*index)==='÷'||settingData.income.formula.substring(2*index-1,2*index)==='/'?'activeSymbol':''"
                @click="symbolClick('income',index,'/')">÷</p>
          </div>
          <div class="contItem">
            <el-select v-model="item.type" clearable placeholder="请选择参数类型">
              <el-option :value="1" label="指标"/>
              <el-option :value="0" label="数值"/>
            </el-select>
            <el-icon v-if="settingData.income.data.length>1" class="delIcon"
                     @click="delControl('income',index)">
              <CircleClose/>
            </el-icon>
            <div v-if="item.type!==''" class="contBox">
              <el-select v-show="item.type==1" ref="elSelectTable" v-model="item.typeName"
                         :filter-method="filterMethod" :popper-append-to-body="false"
                         class="select-unitName select-option-father" clearable filterable
                         popper-class="select_report_and_type">
                <el-option :value="dataItemList">
                  <el-tree
                      ref="treeRef"
                      :expand-on-click-node=true
                      :filter-node-method="filterNode"
                      :load="treeList"
                      :props="props"
                      highlight-current
                      lazy
                      @node-click="clickUnitTreeNodeChild($event,'income',index)"
                  />
                </el-option>
              </el-select>
              <div class="center">
                <el-input v-if="item.type==0" v-model="item.val" class="val" placeholder="请输入"
                          type="number"
                />
                <span v-if="item.type==1&& item.deviceName">{{ item.deviceName }}</span>
                <!--                    <p v-if="item.type==1">{{ item.typeName }}</p>-->
              </div>
              <span v-if="item.type==1" class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div>
          <el-icon class="addIcon" @click="addControl('income')">
            <CirclePlus/>
          </el-icon>
        </div>
      </div>
      <p class="tit">总装机容量</p>
      <div class="contentItem">
        <div class="liItemBox">
          <div class="bgc">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem3.png">
            <p>累计发电量 kwh</p>
          </div>
          <p style="margin-left: 30px">=</p>
        </div>
        <div v-for="(item,index) in settingData.capacity.data" :key="index"
             class="contItemBox">
          <div v-if="index!==0" class="symbol">
            <p
                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='+'?'activeSymbol':''"
                @click="symbolClick('capacity',index,'+')">+</p>
            <p
                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='-'?'activeSymbol':''"
                @click="symbolClick('capacity',index,'-')">-</p>
            <p
                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='×'||settingData.capacity.formula.substring(2*index-1,2*index)==='*'?'activeSymbol':''"
                @click="symbolClick('capacity',index,'*')">×</p>
            <p
                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='÷'||settingData.capacity.formula.substring(2*index-1,2*index)==='/'?'activeSymbol':''"
                @click="symbolClick('capacity',index,'/')">÷</p>
          </div>
          <div class="contItem">
            <el-select v-model="item.type" clearable placeholder="请选择参数类型">
              <el-option :value="1" label="指标"/>
              <el-option :value="0" label="数值"/>
            </el-select>
            <el-icon v-if="settingData.capacity.data.length>1" class="delIcon"
                     @click="delControl('capacity',index)">
              <CircleClose/>
            </el-icon>
            <div v-if="item.type!==''" class="contBox">
              <el-select v-show="item.type==1" ref="elSelectTable" v-model="item.typeName"
                         :filter-method="filterMethod" :popper-append-to-body="false"
                         class="select-unitName select-option-father" clearable filterable
                         popper-class="select_report_and_type">
                <el-option :value="dataItemList">
                  <el-tree
                      ref="treeRef"
                      :expand-on-click-node=true
                      :filter-node-method="filterNode"
                      :load="treeList"
                      :props="props"
                      highlight-current
                      lazy
                      @node-click="clickUnitTreeNodeChild($event,'capacity',index)"
                  />
                </el-option>
              </el-select>
              <div class="center">
                <el-input v-if="item.type==0" v-model="item.val" class="val" placeholder="请输入"
                          type="number"
                />
                <span v-if="item.type==1&& item.deviceName">{{ item.deviceName }}</span>
                <!--                    <p v-if="item.type==1">{{ item.typeName }}</p>-->
              </div>
              <span v-if="item.type==1" class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div>
          <el-icon class="addIcon" @click="addControl('capacity')">
            <CirclePlus/>
          </el-icon>
        </div>
      </div>
      <p class="tit">当前总功率</p>
      <div class="contentItem">
        <div class="liItemBox">
          <div class="bgc">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem4.png">
            <p>累计发电量 kwh</p>
          </div>
          <p style="margin-left: 30px">=</p>
        </div>
        <div v-for="(item,index) in settingData.power.data" :key="index"
             class="contItemBox">
          <div v-if="index!==0" class="symbol">
            <p
                :class="settingData.power.formula.substring(2*index-1,2*index)==='+'?'activeSymbol':''"
                @click="symbolClick('power',index,'+')">+</p>
            <p
                :class="settingData.power.formula.substring(2*index-1,2*index)==='-'?'activeSymbol':''"
                @click="symbolClick('power',index,'-')">-</p>
            <p
                :class="settingData.power.formula.substring(2*index-1,2*index)==='×'||settingData.power.formula.substring(2*index-1,2*index)==='*'?'activeSymbol':''"
                @click="symbolClick('power',index,'*')">×</p>
            <p
                :class="settingData.power.formula.substring(2*index-1,2*index)==='÷'||settingData.power.formula.substring(2*index-1,2*index)==='/'?'activeSymbol':''"
                @click="symbolClick('power',index,'/')">÷</p>
          </div>
          <div class="contItem">
            <el-select v-model="item.type" clearable placeholder="请选择参数类型">
              <el-option :value="1" label="指标"/>
              <el-option :value="0" label="数值"/>
            </el-select>
            <el-icon v-if="settingData.power.data.length>1" class="delIcon"
                     @click="delControl('power',index)">
              <CircleClose/>
            </el-icon>
            <div v-if="item.type!==''" class="contBox">
              <el-select v-show="item.type==1" ref="elSelectTable" v-model="item.typeName"
                         :filter-method="filterMethod" :popper-append-to-body="false"
                         class="select-unitName select-option-father" clearable filterable
                         popper-class="select_report_and_type">
                <el-option :value="dataItemList">
                  <el-tree
                      ref="treeRef"
                      :expand-on-click-node=true
                      :filter-node-method="filterNode"
                      :load="treeList"
                      :props="props"
                      highlight-current
                      lazy
                      @node-click="clickUnitTreeNodeChild($event,'power',index)"
                  />
                </el-option>
              </el-select>
              <div class="center">
                <el-input v-if="item.type==0" v-model="item.val" class="val" placeholder="请输入"
                          type="number"
                />
                <span v-if="item.type==1&& item.deviceName">{{ item.deviceName }}</span>
                <!--                    <p v-if="item.type==1">{{ item.typeName }}</p>-->
              </div>
              <span v-if="item.type==1" class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div>
          <el-icon class="addIcon" @click="addControl('power')">
            <CirclePlus/>
          </el-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, reactive, ref} from "vue"
import './powerStationSetting.less'
import {Back, Delete, CirclePlus, CircleClose} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {getTreeListApi} from "@/api/modules/control";
import qs from "qs";
import {ElMessage, ElMessageBox} from "element-plus";

let router = useRouter()

const goBack = function () {
  router.push('/admin/powerStation')
}


// 配置-------------------------------------------
let settingData = ref({
  generateElectricity: {
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    }]
  },
  income: {
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    }]
  },
  capacity: {
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    }]
  },
  power: {
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    }]
  }
}) //配置列表


// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 参数操作------------------------------------
// 符号点击
const symbolClick = function (index, index_child, e) {
  let str = settingData.value[index].formula
  settingData.value[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加参数值
const addControl = function (index) {
  settingData.value[index].formula = settingData.value[index].formula.concat('+D')
  settingData.value[index].data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    deviceId: '',
    deviceName: '',
  })
  console.log(settingData.value)
}
// 删除参数值
const delControl = function (index, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = settingData.value[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    settingData.value[index].formula = strA.concat(strB)
    settingData.value[index].data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index, index_child) {
  if (data.type == 3) {
    settingData.value[index].data[index_child].typeName = data.name
    settingData.value[index].data[index_child].typeId = data.id
    settingData.value[index].data[index_child].deviceName = data.equipmentName
    settingData.value[index].data[index_child].unit = data.unitName
    settingData.value[index].data[index_child].deviceId = data.equipmentId
    settingData.value[index].data[index_child].dictKey = data.dictKey
    elSelectTable.value[index_child].blur();
  } else {
    // ElMessage({
    //   type: 'info',
    //   message: '当前选择不是指标选项'
    // });
  }
}


// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}


</script>

<style lang="less" scoped>
</style>
