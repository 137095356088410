<template>
  <div class="powerStationItemDevice">
    <div class="headBox">
      <el-button :icon="Plus" type="primary" @click="addClick">关联设备</el-button>
      <!--      <div class="rightOpera">-->
      <!--        <p :class="activeLi===1?'active':''" @click="activeClick(1)">卡片</p>-->
      <!--        <p :class="activeLi===2?'active':''" @click="activeClick(2)">列表</p>-->
      <!--      </div>-->
    </div>
    <div v-if="activeLi===1" class="contentBox">
      <div v-for="(item,index) in dataList" :key="index"
           :style="{width:itemNum===3?'calc(33.333% - 38.66666px)':'calc(25% - 39.5px)',marginRight:(index+1)%itemNum===0?'0':'10px'}"
           class="contItem">
        <div class="head">
          <p class="name">{{ item.name }}</p>
          <el-icon style="cursor: pointer" @click="lookEquipmentDetail(item.id)">
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="centerBox">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/dataItem.png">
          <div class="statusBox">
            <p>状态</p>
            <p :class="item.status==1?'online':'offline'">{{ item.status == 1 ? '在线' : '离线' }}</p>
          </div>
          <div class="warnBox">
            <p>告警</p>
            <p>{{ item.warnNum }}</p>
          </div>
        </div>
        <div class="mainBox">
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val1 }}</span>
              <span class="unit">A</span>
            </p>
            <p>电流</p>
          </div>
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val2 }}</span>
              <span class="unit">V</span>
            </p>
            <p>电压(V)</p>
          </div>
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val3 }}</span>
              <span class="unit">kwh</span>
            </p>
            <p>交流侧总有功率</p>
          </div>
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val4 }}</span>
              <span class="unit">kwh</span>
            </p>
            <p>直流输出功率</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeLi===2" class="contentBoxCopy">
      <!--搜索-->
      <div class="searchBox">
        <div style="display: flex; width: calc(100% - 136px);flex-wrap: wrap">
          <div class="liBox">
            <span>设备名称:</span>
            <el-input v-model="name" clearable placeholder="请输入设备名称"></el-input>
          </div>
          <div class="liBox">
            <span>产品型号:</span>
            <el-input v-model="productModule" clearable placeholder="请输入产品型号"></el-input>
          </div>
          <div class="liBox">
            <span>通讯地址:</span>
            <el-input v-model="address" clearable placeholder="请输入通讯地址"></el-input>
          </div>
          <div class="liBox">
            <span>状态:</span>
            <el-select v-model="useStatus" placeholder="请选择设备状态">
              <el-radio value="">全部</el-radio>
              <el-radio value="1">在线</el-radio>
              <el-radio value="0">离线</el-radio>
            </el-select>
          </div>
        </div>
        <div class="liBox" style="width: 136px;margin-right: 24px;align-items: start">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%;">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面绑定设备信息</p>
            </el-empty>
          </template>
          <el-table-column label="设备名称" min-width="150px" prop="equipmentName"></el-table-column>
          <el-table-column label="设备地址" min-width="150px" prop="equipmentAddress"/>
          <el-table-column label="网关" min-width="150px" prop="gatewayAddress"></el-table-column>
          <el-table-column label="状态" min-width="150px" prop="status">
            <template #default="scope">
              <div v-show="scope.row.useStatus==1" class='statusOnClass'>
                <div class="dotOnClass"></div>
                {{ scope.row.useStatusStr }}
              </div>
              <div v-show="scope.row.useStatus==0" class='statusOutClass'>
                <div class="dotOutClass"></div>
                {{ scope.row.useStatusStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="产品型号" min-width="150px" prop="productModel"></el-table-column>
          <el-table-column label="创建人" min-width="150px" prop="creatorName">
            <template #default="scope">
              <div class="createPeopleBox">
                <img alt="" src="../../../../assets/img/user.png" style="width: 24px;height: 24px;">
                <span>{{ scope.row.creatorName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="最后通讯时间" min-width="150px" prop="createTime"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150px">
            <template #default="scope">
              <div class="opacity">
                <!--                <el-tooltip content="编辑">-->
                <!--                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon8.png"-->
                <!--                       @click="addClick(scope.row.id)">-->
                <!--                </el-tooltip>-->
                <el-tooltip content="设备数据">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon3.png"
                       @click="lookEquipmentDetail(scope.row.id,'1')">
                </el-tooltip>
                <el-popover popper-class="moreOpera2" trigger="hover">
                  <div class="listPox">
                    <p @click="lookEquipmentDetail(scope.row.id,'2')">报警</p>
                    <p @click="delClick(scope.row.id)">删除</p>
                  </div>
                  <template #reference>
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon9.png">
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-show="tableData.length > 0" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                         :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--绑定设备弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="绑定设备" width="460">
      <el-tree
          ref="treeRef"
          :load="treeList"
          :props="prop"
          :render-after-expand="false"
          :render-content="renderContent"
          :show-checkbox="true"
          lazy
          node-key="id"
          show-checkbox
      />
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref, watch} from "vue"
import {Plus, ArrowRight} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import {
  addStationDeviceListApi, delStationDeviceListApi,
  getStationDeviceListApi,
  getTreePrefixWithPowerStationApi
} from "@/api/modules/powerStation";
import qs from 'qs'

const props = defineProps(['custerObj'])
let router = useRouter()
let activeLi = ref(2)
const activeClick = function (idx) {
  activeLi.value = idx
}


// 数据列表
let dataList = ref([])


// 点击查看设备详情
const lookEquipmentDetail = function (id) {
  router.push({
    path: '/admin/powerStationEquipment',
    query: {
      id: id
    }
  })
}


// 列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let name = ref()
let productModule = ref()
let useStatus = ref()
let address = ref()
// 参数表格数据
let tableData = ref([{}, {}, {}, {}, {}])
// 获取列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getStationDeviceListApi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    type: 1,
    powerStationId: custerObj.value.id,
    name: name.value,
    productModule: productModule.value,
    useStatus: status.value,
    address: address.value,
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}
// 重置
const resetFun = function () {
  pageNumber.value = 1
  pageSize.value = 20
  name.value = ''
  productModule.value = null
  useStatus.value = ''
  address.value = ''
  getListFun()
}


// 编辑设备-----------------------------------------------------------------
let dialogFormVisible = ref(false) //新增设备弹窗
let equipmentList = ref([]) //关联设备列表

// 点击编辑设备
const addClick = function (id) {
  dialogFormVisible.value = true
  treeRef.value.setCheckedNodes([])
}
// 点击保存
const save = async function () {
  let checkArrId = []
  let checkArr = treeRef.value.getCheckedNodes()
  // 将选中项目下的设备加到数组
  for (let i = 0; i < checkArr.length; i++) {
    checkArrId.push(checkArr[i].id)
    if (checkArr[i].id.includes('p') && checkArr[i].subList.length === 0) {
      await getTreePrefixWithPowerStationApi(qs.stringify({
        id: checkArr[i].id,
        type: 1
      })).then(res => {
        if (res.data.code === 0) {
          for (let j = 0; j < res.data.result.length; j++) {
            checkArrId.push(res.data.result[j].id)
          }
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }


  addStationDeviceListApi(qs.stringify({
    type: 1,
    powerStationId: custerObj.value.id,
    equipmentIdList: checkArrId.join(',')
  })).then(res => {
    if (res.data.code === 0) {
      getListFun()
      dialogFormVisible.value = false
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 点击删除-----------------------------------------------------------------------------------------
const delClick = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    delStationDeviceListApi(qs.stringify({
      type: 1,
      powerStationId: custerObj.value.id,
      equipmentId: id
    })).then(res => {
      if (res.data.code === 0) {
        getListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 资产树
// 树形结构处理-----------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  if (node.level === 0) {
    await getTreePrefixWithPowerStationApi(qs.stringify({
      id: 0,
      type: 1
    })).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else if (node.data && node.data.subList && node.data.subList.length > 0) {
    return resolve(node.data.subList)
  } else {
    await getTreePrefixWithPowerStationApi(qs.stringify({
      id: node.data.id,
      type: 1
    })).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 项目树配置
let prop = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data && node.data.id && node.data.id.includes('e')) {
      return true
    }
  },
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 控制一排三个还是四个
let itemNum = ref(3)
onMounted(() => {
  getListFun()


  if (window.innerWidth > 1800) {
    itemNum.value = 4
  } else {
    itemNum.value = 3
  }
  window.addEventListener('resize', () => {
    if (window.innerWidth > 1800) {
      itemNum.value = 4
    } else {
      itemNum.value = 3
    }
  });
});


let custerObj = ref()
watch(() => props.custerObj, (newVal) => {
  custerObj.value = newVal
  getListFun()
}, {immediate: true, deep: true})

</script>

<style lang="less" scoped>
.powerStationItemDevice {
  width: 100%;
  height: 100%;

  .headBox {
    width: calc(100% - 32px);
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .rightOpera {
      width: 134px;
      height: 32px;
      background: rgba(22, 93, 255, 0.1);
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 64px;
        height: 26px;
        cursor: pointer;
        color: #999999;
        text-align: center;
        line-height: 26px;
      }

      .active {
        background: #165DFF;
        border-radius: 3px;
        color: #FFFFFF;
      }
    }
  }

  //卡片
  .contentBox {
    height: calc(100% - 74px);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .contItem {
      width: calc(25% - 39.5px);
      height: 258px;
      background: #FFFFFF;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
      border-radius: 6px;
      padding: 16px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 10px;


      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
      }

      .centerBox {
        width: 90%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 80px;
          height: 80px;
        }

        .statusBox, .warnBox {
          height: 80px;
          margin-left: 24px;

          p:nth-child(1) {
            font-weight: 600;
            position: relative;
            margin-top: 10px;
          }

          p:nth-child(1)::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 28px;
            height: 4px;
            background: #3A97FE;
          }
        }

        .statusBox {
          p:nth-child(2) {
            width: 40px;
            height: 24px;
            border-radius: 4px;
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            color: #fff;
            margin-top: 13px;
          }

          .online {
            background: #67C23A;

          }

          .offline {
            background: #999999;
          }
        }

        .warnBox {
          p:nth-child(2) {
            font-weight: 500;
            font-size: 20px;
            color: #FF1111;
            margin-top: 11px;
          }
        }
      }

      .mainBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .mainItem {
          width: calc(50% - 5px);
          height: 64px;
          background: rgba(22, 93, 255, 0.05);
          box-shadow: inset 0px 0px 20px 0px rgba(22, 93, 255, 0.1);
          border-radius: 3px;
          text-align: center;
          margin-top: 10px;

          .val {
            font-size: 24px;
            margin-right: 5px;

          }

          p:nth-child(1) {
            font-weight: 600;
          }

          p:nth-child(2) {
            font-size: 12px;
            color: #535F7F;
          }
        }
      }
    }

    .contItem:nth-child(4n) {
      margin-right: 0;
    }
  }

  //列表
  .contentBoxCopy {
    width: 100%;
    height: calc(100% - 74px);
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
    overflow: auto;
    //搜索
    .searchBox {
      padding: 24px 24px 8px;
      width: calc(100% - 48px);
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .liBox {
        margin-bottom: 16px;
        margin-right: 24px;
        display: flex;
        align-items: center;

        > p {
          margin-bottom: 8px;
        }

        > span {
          display: inline-block;
          width: 60px;
          margin-right: 8px;
        }

        .el-select, .el-input {
          width: 220px;
        }
      }
    }

    //列表内容
    .contain {
      width: calc(100% - 48px);
      padding: 0 24px 24px;
      overflow: auto;

      //表格
      .el-table {
        color: rgba(3, 10, 26, 0.85);
        width: calc(100% - 20px);
        overflow: auto;

        th, td {
          padding: 0;
        }

        .cell {
          padding: 14px 16px;

          .btn {
            display: inline-block;
            color: #165DFF;
            margin-right: 14px;
            cursor: pointer;
          }

          .statusOnClass {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 60px;
            height: 22px;
            background: #E8FFEA;
            border-radius: 3px;
            color: #00B42A;

            .dotOnClass {
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background: #00B42A;
            }
          }

          .statusOutClass {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 60px;
            height: 22px;
            background: #FFECE8;
            border-radius: 3px;
            color: #F53F3F;

            .dotOutClass {
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background: #F53F3F;
            }
          }

          .cont {
            display: -webkit-box; //对象作为弹性伸缩盒子模型显示
            overflow: hidden; //溢出隐藏
            -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
            -webkit-line-clamp: 1; //设置 块元素包含的文本行数
            cursor: pointer;
          }

          .opacity {
            img {
              width: 24px;
              height: 24px;
              margin-right: 16px;
              cursor: pointer;
            }
          }
        }
      }

      //分页
      .pag {
        width: 100%;
        height: 32px;
        padding: 16px 0 0 0;

        .el-pagination {
          float: right;
          margin-right: 12px;
        }
      }
    }
  }


  .el-dialog {
    max-height: 73%;
    overflow-y: auto;
    overflow-x: hidden;

    .el-dialog__body {
      height: calc(100% - 180px);
      overflow: auto;
    }


    .el-tree-node__content {
      height: 40px;
      border-radius: 3px;
      border: 1px solid #DCDCDC;
    }

    .is-current {
      > .el-tree-node__content {
        background: #e6eafa;

        span {
          //color: #2A6EF3;
        }
      }
    }
  }
}
</style>
