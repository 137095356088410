import {api} from "@/api";

// 获取项目一次图数据
export const getOnceImgDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getOnePicAddress',
        method: 'POST',
        data: data
    })
}
// 更新项目一次图数据
export const editOnceImgDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/updateOnePicAddress',
        method: 'POST',
        data: data
    })
}

// 获取一次图数据
export const getEveryOnceImgDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getOnePicData',
        method: 'POST',
        data: data
    })
}


// 得力一次图数据
export const getDeliOnePicDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/onePicData/getDeliOnePicData',
        method: 'POST',
        data: data
    })
}
