<template>
  <div class="powerStationItem">
    <div class="headBox">
      <div class="headLeftBox">
        <p :class="activeIdx===1?'active':''" @click="activeClick(1)">概览</p>
        <p :class="activeIdx===2?'active':''" @click="activeClick(2)">设备</p>
      </div>
      <div class="headRightBox">
        <div class="rightItem">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/weatherIcon1.png">
          <div>
            <p>3.95 h/day</p>
            <p>峰值日照时数</p>
          </div>
        </div>
        <div class="rightItem">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/weatherIcon2.png">
          <div>
            <p>3℃/33%</p>
            <p>环境温湿度</p>
          </div>
        </div>
        <div class="rightItem">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/weatherIcon3.png">
          <div>
            <p>4-5级</p>
            <p>风力</p>
          </div>
        </div>
      </div>
    </div>
    <!--概览或设备的内容-->
    <div class="content">
      <Overview v-if="activeIdx===1" :custerObj="custerObj" @isShowMapFun="isShowMapFun"/>
      <Device v-if="activeIdx===2" :custerObj="custerObj"/>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {reactive, ref, watch} from "vue"
import Overview from './comp/overview'
import Device from './comp/device'

const props = defineProps(['custerObj'])
let custerObj = ref()
watch(() => props.custerObj, (newVal) => {
  custerObj.value = newVal
}, {immediate: true, deep: true})

const emit = defineEmits(['isShowMapFun']);
const isShowMapFun = function () {
  emit('isShowMapFun', 2);
}

let activeIdx = ref(1)
const activeClick = function (idx) {
  activeIdx.value = idx
}

</script>

<style lang="less" scoped>
.powerStationItem {
  width: 100%;
  height: 100%;

  .headBox {
    width: calc(100% - 32px);
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .headLeftBox {
      width: 272px;
      height: 40px;
      background: #E8EFFF;
      border-radius: 4px;
      display: flex;

      p {
        flex: 1;
        text-align: center;
        line-height: 40px;
        border-radius: 4px;
        color: #333333;
        cursor: pointer;
      }

      .active {
        background: #165DFF;
        color: #FFFFFF;
      }
    }

    .headRightBox {
      display: flex;

      .rightItem {
        margin-left: 40px;
        display: flex;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
          margin-right: 16px;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          p:first-child {
            color: #535F7F;
          }

          p:last-child {
            color: #999999;
          }
        }
      }
    }
  }

  .content {
    padding: 0;
    width: 100%;
    height: calc(100% - 90px);
  }
}
</style>
