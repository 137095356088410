<template>
  <div>
    <div class="title" style="display: flex; align-items: center">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/admin/permit' }"
        >用电作业许可证
        </el-breadcrumb-item
        >
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <el-card header="用户信息" shadow="never" style="margin-bottom: 16px">
        <el-form :inline="true" disabled label-width="auto">
          <el-form-item label="用户名称" prop="">
            <el-input style="width: 240px"/>
          </el-form-item>
          <el-form-item label="用户手机号码" prop="">
            <el-input style="width: 240px"/>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card header="用电箱信息" shadow="never" style="margin-bottom: 16px">
        <el-form :inline="true" disabled label-width="auto">
          <el-form-item label="用电箱名称" prop="">
            <el-input style="width: 240px"/>
          </el-form-item>
          <el-form-item label="用电箱编号" prop="boxNumber">
            <el-input v-model="form.boxNumber" style="width: 240px"/>
          </el-form-item>
          <el-form-item label="蓝牙编号" prop="">
            <el-input style="width: 240px"/>
          </el-form-item>
          <el-form-item label="用电时间" prop="" style="width: 480px">
            <el-date-picker
                end-placeholder="结束时间"
                range-separator="-"
                start-placeholder="开始时间"
                type="datetimerange"
                value-format="YYYY-MM-DD HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="二维码" prop="boxQrCode">
            <ImagePreview :height="100" :src="form.boxQrCode" :width="100"/>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card
          header="用电作业许可证"
          shadow="never"
          style="margin-bottom: 16px"
      >
        <el-timeline style="max-width: 600px">
          <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :color="activity.color"
              :hollow="activity.hollow"
              :icon="activity.icon"
              :size="activity.size"
              :timestamp="activity.timestamp"
              :type="activity.type"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import {getPermit} from '@/api/modules/permit'
import ImagePreview from '@/components/ImagePreview.vue'
import {ref} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()
const form = ref({})
const activities = [
  {
    content: '用电员：XXXX提交用电申请',
    timestamp: '2024-10-12 12:00:00',
    type: 'primary',
    hollow: true,
  },
  {
    content: '用电员：XXXX提交用电申请',
    timestamp: '2024-10-12 12:00:00',
    type: 'primary',
    hollow: true,
  },
  {
    content: '电箱合闸开始用电',
    timestamp: '2024-10-12 12:00:00',
  },
  {
    content: '电箱合闸开始用电',
  },
]

getPermit(route.query.id).then((res) => {
  form.value = res.data.result
})
</script>

<style>
/*.title {*/
/*  height: 48px;*/
/*  padding-left: 36px;*/
/*  line-height: 48px;*/
/*}*/

/*.content {*/
/*  padding: 0 28px 24px;*/
/*}*/
</style>
