<template>
  <div class="totalPowerStation">
    <!--上部分-->
    <div class="topBox">
      <!--上部分左侧-->
      <div class="topLeftBox">
        <div class="titleBox">
          <p class="title">电站信息</p>
          <div class="more">
            <el-tooltip content="地图分布" placement="top">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/positionIcon.png"
                   @click="emit('isShowMapFun', 2);">
            </el-tooltip>
            <!--            <el-icon @click="goDetail">-->
            <!--              <ArrowRight/>-->
            <!--            </el-icon>-->
          </div>
        </div>
        <div class="topLeftItemBox">
          <div class="topLeftItem1">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem.png">
            <div class="topLeftCenterRight">
              <p class="valueBox">
                <span class="val" style="margin-right: 5px">{{ stationData.stationNumber }}</span>
                <span class="unit">个</span>
              </p>
              <p class="describe">已接入站点</p>
            </div>
          </div>
          <div class="topLeftItem2">
            <div class="item">
              <p class="val">{{ stationData.onlineEquipmentNumber }}</p>
              <p class="describe">在线设备数</p>
            </div>
            <div class="item" style="cursor: pointer" @click="lookOfflineFun">
              <p class="val">{{ stationData.offlineEquipmentNumber }}</p>
              <p class="describe">离线设备数</p>
              <el-icon>
                <ArrowRight/>
              </el-icon>
            </div>
            <div class="item" style="cursor: pointer" @click="lookWarnFun">
              <p class="val">{{ stationData.warnEquipmentNumber }}</p>
              <p class="describe">告警设备数</p>
              <el-icon>
                <ArrowRight/>
              </el-icon>
            </div>
          </div>
        </div>
      </div>
      <!--上部分右侧-->
      <div class="topRightBox">
        <div class="titleBox">
          <p class="title">运行分析</p>
          <!--          <div class="more">-->
          <!--            <span>配置</span>-->
          <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/setIcon.png"-->
          <!--                 style="cursor:pointer;"-->
          <!--                 @click="settingClick">-->
          <!--          </div>-->
        </div>
        <div class="topRightContent">
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem1.png">
            <div class="cont">
              <p class="val">{{ stationData.totalPowerGeneration }}</p>
              <p class="describe">累计发电量 kwh</p>
            </div>
          </div>
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem2.png">
            <div class="cont">
              <p class="val">{{ stationData.totalIncome }}</p>
              <p class="describe">累计收益 元</p>
            </div>
          </div>
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem3.png">
            <div class="cont">
              <p class="val">{{ stationData.totalCapacity }}</p>
              <p class="describe">总装机容量 kw</p>
            </div>
          </div>
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem4.png">
            <div class="cont">
              <p class="val">{{ stationData.totalRate }}</p>
              <p class="describe">当前总功率 kw</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--中间部分-->
    <div class="centerBox">
      <div class="centerBoxItem">
        <div class="title">
          <p>当日发电量排行</p>
          <el-icon>
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="ulBox">
          <div v-for="(item,index) in stationData.dailyPowerGenerationList" :key="index" class="liBox">
            <div class="liBoxLeft">
              <p>{{ index + 1 }}</p>
              <p class="name">{{ item.key }}</p>
            </div>
            <p class="nameBox">
              <span class="val">{{ item.value }}</span>
              <span class="unit">kwh</span>
            </p>
          </div>
        </div>
      </div>
      <div class="centerBoxItem">
        <div class="title">
          <p>累计发电量排行</p>
          <el-icon>
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="ulBox">
          <div v-for="(item,index) in stationData.totalPowerGenerationList" :key="index" class="liBox">
            <div class="liBoxLeft">
              <p>{{ index + 1 }}</p>
              <p class="name">{{ item.key }}</p>
            </div>
            <p class="nameBox">
              <span class="val">{{ item.value }}</span>
              <span class="unit">kwh</span>
            </p>
          </div>
        </div>
      </div>
      <div class="centerBoxItem">
        <div class="title">
          <p>最近告警列表</p>
          <el-icon style="cursor: pointer" @click="warnClick">
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="ulBox">
          <div class="liBox liBoxWarn">
            <p class="name">光伏电站1</p>
            <p class="type">xx告警类型</p>
            <p class="status">未处理</p>
          </div>
          <div class="liBox ">
            <p class="name">光伏电站1</p>
            <p class="type">xx告警类型</p>
            <p class="status">未处理</p>
          </div>
        </div>
      </div>
    </div>
    <!--下部分-->
    <div class="footerBox">
      <p class="title">发电趋势</p>
      <div class="timer">
        <p :class="timeActive===1?'active':''" @click="selectTimeFun(1)">按日</p>
        <p :class="timeActive===2?'active':''" @click="selectTimeFun(2)">按月</p>
        <p :class="timeActive===3?'active':''" @click="selectTimeFun(3)">按年</p>
      </div>
      <div class="chart"></div>
    </div>
    <!--离线站点弹窗-->
    <el-dialog v-model="offlineDialog" :close-on-click-modal="false" class="offlineDialog" title="查看详情" width="50%">
      <el-table v-loading="offlineLoading" :data="offlineTableData" class="tableBox" style="width: 100%"
                table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="名称" prop="name"/>
        <el-table-column label="产品型号" prop="productModule"/>
        <el-table-column label="协议类型" prop="type"/>
        <el-table-column label="地址" prop="address"/>
        <el-table-column label="创建人" prop="createPeople">
          <template #default="scope">
            <div class="createPeopleBox">
              <img alt="" src="../../../assets/img/user.png">
              <span>{{ scope.row.createPeople }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template #default="scope">
            <div :class="scope.row.status == 1?'online':'offline'" class="statusPox">
              <p class="dotOnClass"></p>
              <p>{{ scope.row.status == 1 ? '在线' : '离线' }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-tooltip content="设备详情">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                   @click="lookEquipmentDetail(scope.row.id)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--告警设备弹窗-->
    <el-dialog v-model="warnDialog" :close-on-click-modal="false" class="warnDialog" title="查看详情" width="50%">
      <el-table v-loading="warnLoading" :data="warnTableData" class="tableBox" style="width: 100%"
                table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="名称" prop="name"/>
        <el-table-column label="发生时间" prop="happenTime"/>
        <el-table-column label="报警数量" prop="warn"/>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-tooltip content="设备详情">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                   style="margin-right: 10px"
                   @click="lookEquipmentDetail(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="告警详情">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon2.png"
                   @click="lookWarn(scope.row.id)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--告警详情弹窗-->
    <el-dialog v-model="warnDetailDialog" :close-on-click-modal="false" class="warnDetailDialog" title="查看详情"
               width="50%">
      <el-table v-loading="warnLoading" :data="warnTableData2" class="tableBox" style="width: 100%"
                table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="发生时间" prop="happenTime"/>
        <el-table-column label="类别" prop="type"/>
        <el-table-column class-name="level" label="级别" prop="level">
          <template #default="scope">
            <p v-if="scope.row.level===3" style="background: #FF1111">高风险</p>
            <p v-if="scope.row.level===2" style="background: #FF7811">中风险</p>
            <p v-if="scope.row.level===1" style="background: #FFBB11">低风险</p>
            <p v-if="scope.row.level===0" style="background: #00B42A">正常</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template #default="scope">
            <el-tooltip content="结束报警">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon6.png"
                   style="margin-right: 10px" @click="endClick(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="转工单">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon7.png"
                   style="margin-right: 10px" @click="wordClick(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="查看当时值">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                   @click="lookValue(scope.row.id)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, h, onMounted, reactive, ref, watch} from "vue"
import './totalPowerStation.less'
import {ArrowRight} from '@element-plus/icons-vue'
import * as echarts from "echarts";
import {useRouter} from "vue-router/dist/vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import {getPhotovoltaicChartDataApi, getPhotovoltaicDataApi} from "@/api/modules/powerStation";
import qs from "qs";

let router = useRouter()
const emit = defineEmits(['isShowMapFun']);
const props = defineProps(['custerObj'])

let custerObj = ref({})
// 点击查看电站详情
const goDetail = function () {
  router.push('/admin/powerStationDetail')
}
// 点击查看告警列表
const warnClick = function () {
  router.push('/admin/powerStationWarn')
}
// 点击进行配置
const settingClick = function () {
  router.push('/admin/powerStationSetting')
}


// 电站数据-------------------------------------------------------------------
let stationData = ref({})
const getPhotovoltaicDataFun = function () {
  getPhotovoltaicDataApi(qs.stringify({
    id: custerObj.value.id
  })).then(res => {
    if (res.data.code === 0) {
      stationData.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 图表-------------------------------------------------------------------
// 年月日选择
let timeActive = ref(1)
const selectTimeFun = function (idx) {
  timeActive.value = idx
  getPhotovoltaicChartDataFun()
}

// 获取图表数据
let echartsDataX = ref([])
let echartsData = ref([])
const getPhotovoltaicChartDataFun = function () {
  getPhotovoltaicChartDataApi(qs.stringify({
    id: custerObj.value.id,
    timeType: timeActive.value
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value = res.data.result.chartDataTimeList
      echartsData.value = res.data.result.chartDataValueList[0].value
      chartLoad()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  })
}
// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 50,
      right: 20,
      bottom: 20,
      top: 40,
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        }
      },
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#405873'
        }
      },
    },
    series: [{
      name: '发电量',
      type: 'line',
      data: echartsData.value,
      color: '#00E5A6',
      lineStyle: {
        width: 2,
        shadowColor: 'rgba(0,229,166,0.2)', // 阴影颜色
        shadowBlur: 4, // 阴影的模糊大小
        shadowOffsetY: 4 // 阴影的垂直偏移
      }
    }]
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }

}


// 离线站点--------------------------------------------------------------------------
let offlineDialog = ref(false)
let offlineLoading = ref(false)
let offlineTableData = ref([
  {
    id: 0,
    name: '设备1',
    productModule: '12345',
    type: '376.1',
    address: '000001',
    createPeople: 'admin',
    status: 0,
  },
  {
    id: 0,
    name: '设备1',
    productModule: '12345',
    type: '376.1',
    address: '000001',
    createPeople: 'admin',
    status: 0,
  },
  {
    id: 0,
    name: '设备1',
    productModule: '12345',
    type: '376.1',
    address: '000001',
    createPeople: 'admin',
    status: 0,
  }
])
// 点击查看离线设备
const lookOfflineFun = function () {
  offlineDialog.value = true
}
// 点击查看设备详情
const lookEquipmentDetail = function (id) {
  router.push({
    path: '/admin/powerStationEquipment',
    query: {
      id: id
    }
  })
}


// 报警设备--------------------------------------------------------------------------
let warnDialog = ref(false)
let warnLoading = ref(false)
let warnTableData = ref([
  {
    id: 0,
    name: '设备1',
    happenTime: '2024-09-10 12:45:04',
    warn: '高风险4分、中风险0个、低风险0个',
  },
  {
    id: 0,
    name: '设备1',
    happenTime: '2024-09-10 12:45:04',
    warn: '高风险4分、中风险0个、低风险0个',
  },
  {
    id: 0,
    name: '设备1',
    happenTime: '2024-09-10 12:45:04',
    warn: '高风险4分、中风险0个、低风险0个',
  }
])
// 点击查看告警设备
const lookWarnFun = function () {
  warnDialog.value = true
}
// 点击查看告警详情
let warnDetailDialog = ref(false)  //告警详情弹窗
// 告警详情数据
let warnTableData2 = ref([
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 0
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 1
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 2
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 3
  }
])
const lookWarn = function (id) {
  warnDetailDialog.value = true
}
// 结束报警
const endClick = function () {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '结束报警',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要结束此报警，结束后数据不可恢复'),
    ])
  }).then(() => {
    // delSearchListApi(id).then(res => {
    //   if (res.data.code === 200) {
    //     getSearchListFun()
    //     ElMessage({
    //       type: 'success',
    //       message: '结束报警成功'
    //     });
    //   } else {
    //     ElMessage({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    // }).catch(err => {
    //   console.log(err);
    // })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消结束报警'
    });
  });
}


onMounted(() => {
  // getPhotovoltaicDataFun()
  // getPhotovoltaicChartDataFun()
})

watch(() => props.custerObj, (newVal) => {
  custerObj.value = newVal
  if (newVal && newVal.id) {
    getPhotovoltaicDataFun()
    getPhotovoltaicChartDataFun()
  }
}, {immediate: true, deep: true})

</script>

<style lang="less" scoped>
</style>
