import {api} from "@/api";

// 电站-------------------------------------------------------------------------------
// 获取电站树
export const getStationTreeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getTree',
        method: 'POST',
        data: data
    })
}
// 新增电站
export const addStationTreeList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/save',
        method: 'POST',
        data: data
    })
}
// 删除电站
export const delStationTreeList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/delete',
        method: 'POST',
        data: data
    })
}
// 获取电站年月日图表数据
export const getPhotovoltaicChartDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getPhotovoltaicChartData',
        method: 'POST',
        data: data
    })
}

// 获取电站数据
export const getPhotovoltaicDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getPhotovoltaicData',
        method: 'POST',
        data: data
    })
}


// 获取电站地图数据
export const getPhotovoltaicMapDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getPhotovoltaicMapData',
        method: 'POST',
        data: data
    })
}


// 电站设备-------------------------------------------------------------------------------
// 获取电站设备
export const getStationDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationEquipment/page',
        method: 'POST',
        data: data
    })
}

// 获取电站设备详情
export const getStationDeviceDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getDetail',
        method: 'POST',
        data: data
    })
}


// 新增电站设备
export const addStationDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationEquipment/add',
        method: 'POST',
        data: data
    })
}
// 删除电站设备
export const delStationDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationEquipment/delete',
        method: 'POST',
        data: data
    })
}

// 关联设备的树形结构
export const getTreePrefixWithPowerStationApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getTreePrefixWithPowerStation',
        method: 'POST',
        data: data
    })
}



