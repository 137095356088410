import { api } from '@/api'

// 查询用电箱管理系统-用电箱管理列表
export function listBox(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/page',
    method: 'get',
    params: query,
  })
}

// 查询用电箱管理系统-用电箱管理详细
export function getBox(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/detail/' + id,
    method: 'get',
  })
}

// 新增用电箱管理系统-用电箱管理
export function addBox(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/add',
    method: 'post',
    data: data,
  })
}

// 修改用电箱管理系统-用电箱管理
export function updateBox(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/update',
    method: 'post',
    data: data,
  })
}

// 删除用电箱管理系统-用电箱管理
export function delBox(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/remove/' + id,
    method: 'post',
  })
}

// 设备列表
export function listDevice(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/device/list',
    method: 'get',
    params: query,
  })
}

// 设备指标列表
export function listItem(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/item/list/' + id,
    method: 'get',
  })
}
