import {api, api2, httpHost} from "@/api";
import axios from "axios";

const api3 = axios.create({
    timeout: 50000,
    // baseURL: 'http://192.168.1.151:9000/' //本地 惠生
    // baseURL: 'http://v3.380v.com:5090/'  //本地  惠生
    // baseURL: 'http://192.168.3.20:9000/' //本地  得力
    // baseURL: 'http://10.192.3.20:9000/' //本地  得力
    baseURL: 'https://interface.380v.com/'  //线上
    // baseURL: httpHost  //本地
})
// 获取宏恩光伏大屏数据
export const hngfScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getHongEnPhotovoltaicScreenData',
        method: 'POST',
    })
}
//获取罗杰斯光伏大屏数据
export const ljsScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getLuoJieSiPhotovoltaicScreenData',
        method: 'POST',
    })
}
// 获取丰轩乐储能大屏数据
export const fxlcnScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getFengXuanLeStoreEnergyScreenData',
        method: 'POST',
    })
}

// 获取宏恩储能大屏数据
export const hncnScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getHongEnStoreEnergyScreenData',
        method: 'POST',
    })
}
// 获取获取宏恩微电网大屏数据
export const hnwwScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getHongEnNetScreenData',
        method: 'POST',
    })
}

//获取丰轩乐微网大屏数据
export const fxlwwScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getFengXuanLeNetScreenData', //风轩乐
        method: 'POST',
    })
}


// 获取天气列表（吴江区）320509
export const getWeatherApi = (extensions) => {
    return api2({
        url: `v3/weather/weatherInfo?key=4968190ec63cd5a06b3b591f94f9481e&city=320509&extensions=${extensions}`,
        method: 'GET',
    })
}
//获取天气（苏州市）320500
export const getWeatherApi2 = (extensions) => {
    return api2({
        url: `v3/weather/weatherInfo?key=4968190ec63cd5a06b3b591f94f9481e&city=320500&extensions=${extensions}`,
        method: 'GET',
    })
}
//获取宏恩设备名称及功率
export const getDeviceListApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getHongEnEquipmentScreenData',//洪恩
        // url: '/zouk/rest/screen/getFengXuanLeEquipmentScreenData', //风轩乐
        method: 'POST',
    })
}
//获取丰轩乐设备名称及功率
export const getFxlDeviceListApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getFengXuanLeEquipmentScreenData', //风轩乐
        method: 'POST',
    })
}

// 获取惠生能源监控大屏数据
export const getHsHomeScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/localScreenData/getHuiShenEnergyMonitorScreenData',
        method: 'POST',
    })
}


// 获取惠生能源监控大屏数据
export const getHsyqScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/localScreenData/getHuiShenAirMonitorScreenData',
        method: 'POST',
    })
}
// 获取惠生用水监控大屏数据
export const getHsysScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/localScreenData/getHuiShenWaterMonitorScreenData',
        method: 'POST',
    })
}

// 获取惠生用电监控大屏数据
export const getHsydScreenApi = () => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/localScreenData/getHuiShenElectricMonitorScreenData',
        method: 'POST',
    })
}
// 获取报表数据
export const getReportDataApi = (data) => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/localScreenData/getMutiData',
        method: 'POST',
        data: data
    })
}


// 获取所有设备树
export const getAllTreeApi = (data) => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getAllTree',
        method: 'POST',
        data: data
    })
}


// 获取罗杰斯大屏设备数据
export const getLJSEquipmentDataApi = (data) => {
    return api3({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/screen/getLuoJieSiEquipmentScreenData',
        method: 'POST',
        data: data
    })
}
