<template>
  <div class="permit">
    <div class="title">
      <p>
        <span>用电作业许可证</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">用户名称</label>
          <el-input
            v-model="queryParams.userName"
            prefix-icon="Search"
            clearable
            placeholder="请输入用户名称"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">电箱名称</label>
          <el-input
            v-model="queryParams.boxName"
            prefix-icon="Search"
            clearable
            placeholder="请输入电箱名称"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <!-- <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建用电作业许可证
        </el-button> -->
        <el-table v-loading="loading" :data="permitList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建用电作业许可证</p>
            </el-empty>
          </template>
          <el-table-column label="用户名称" align="center" prop="userName" />
          <el-table-column label="手机号码" align="center" prop="phoneNumber" />
          <el-table-column label="电箱名称" align="center" prop="boxName" />
          <el-table-column
            label="用电开始时间"
            align="center"
            prop="startTime"
          />
          <el-table-column label="用电结束时间" align="center" prop="endTime" />
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleDetail(scope.row)">详情</p>
                <!-- <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form ref="permitRef" :model="form" :rules="rules" label-width="auto">
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './permit.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listPermit,
  getPermit,
  delPermit,
  addPermit,
  updatePermit,
} from '@/api/modules/permit'
import { useRouter } from 'vue-router'

const router = useRouter()

const permitList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const permitRef = ref(null)

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    userName: null,
    boxName: null,
  },
  rules: {},
})

const { queryParams, form, rules } = toRefs(data)

/** 查询用电作业许可证列表 */
function getList() {
  loading.value = true
  listPermit(queryParams.value).then((response) => {
    permitList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
  }
  permitRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    userName: null,
    boxName: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  open.value = true
  title.value = '新建用电作业许可证'
}

function handleDetail(row) {
  router.push({
    path: '/admin/permitDetail',
    query: {
      id: row.id,
    },
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  getPermit(row.id).then((response) => {
    form.value = response.data.result
    open.value = true
    title.value = '修改用电作业许可证'
  })
}

/** 提交按钮 */
function submitForm() {
  permitRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updatePermit(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addPermit(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除用电作业许可证',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此用电作业许可证，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delPermit(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}
</script>
