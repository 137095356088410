<template>
  <div class="deliOnceImg3">
    <div class="onceImgBgc">
      <div class="bgcImg">
        <img alt="" class="bgc"
             src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/onceImg/deli/onceImgDeliB1.png">
        <div v-for="(item,index) in data.dataList" :key="index" :class="'tableItem'+(index+1)" class="tableItem">
          <p>Ua (V) : {{ item.ua }}</p>
          <p>Ub (V) : {{ item.ub }}</p>
          <p>Uc (V) : {{ item.uc }}</p>
          <p>Ia (A) : {{ item.ia }}</p>
          <p>Ib (A) : {{ item.ib }}</p>
          <p>Ic (A) : {{ item.ic }}</p>
          <p>P (kw) : {{ item.p }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, ref} from "vue";
import {getDeliOnePicDataApi, getEveryOnceImgDataApi} from "@/api/modules/onceImg";
import {ElMessage} from "element-plus";
import qs from "qs";

// 一次图数据
let data = ref({})

const resizeFun = function () {
  nextTick(() => {
    let ele = document.querySelector('.onceImgBgc')
    let scaleX = (ele.clientWidth) / 840
    let scaleY = (ele.clientHeight) / 630
    document.querySelector('.bgcImg').style.transform = `scale(${scaleX},${scaleY})`
  })
}
onMounted(() => {
  resizeFun()
  window.onresize = () => {
    resizeFun()
  }

  getDeliOnePicDataApi(qs.stringify({
    type: 3
  })).then(res => {
    if (res.data.code === 0) {
      data.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less">
.deliOnceImg3, .deliOnceImg3 .onceImgBgc {
  width: 100%;
  height: 100% !important;

  .bgcImg {
    width: 840px;
    height: 630px;
    transform-origin: 0% 0%;
    position: relative;

    .bgc {
      width: 100%;
      height: 100%;
    }

    .tableItem {
      font-size: 8px;
      display: none;

      p {
        color: #EAFA42;
        font-size: 8px;
        margin-bottom: 2px;
        transform: scale(0.8);
      }

      p:nth-child(2n) {
        color: #2EEF28;
      }

      p:nth-child(3n) {
        color: #FF0000;
      }

      p:nth-child(7) {
        color: #00F6F4;
      }
    }

    .tableItem1 {
      display: block;
      position: absolute;
      top: 414px;
      left: 60px;
    }

    .tableItem2 {
      display: block;
      position: absolute;
      top: 414px;
      left: 147px;
    }

    .tableItem3 {
      display: block;
      position: absolute;
      top: 414px;
      left: 227px;
    }

    .tableItem4 {
      display: block;
      position: absolute;
      top: 486px;
      left: 433px;
    }

    .tableItem5 {
      display: block;
      position: absolute;
      top: 486px;
      left: 515px;
    }

    .tableItem6 {
      position: absolute;
      top: 486px;
      left: 589px;
    }

    .tableItem7 {
      display: block;
      position: absolute;
      top: 244px;
      left: 675px;
    }

    .tableItem8 {
      display: block;
      position: absolute;
      top: 371px;
      left: 747px;
    }

  }
}
</style>
