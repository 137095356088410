<template>
  <div class="addRateAllocationPage">
    <div class="title">
      <p>
        <span @click="goBack">费率配置</span>
        <span> / </span>
        <span>详情</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        详情
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <el-form
            ref="strategyFormRef"
            :model="strategyForm"
            :rules="strategyRules"
            class="strategyForm"
            status-icon
        >
          <!--选择省市-->
          <div class="topBox" style="position: relative">
            <el-form-item class="topFormItem" label="方案名称" prop="name" required>
              <el-input v-model="strategyForm.name" :disabled="true" placeholder="请输入方案名称"/>
            </el-form-item>
            <el-form-item class="topFormItem" label="区域" prop="provinceName">
              <el-select v-model="strategyForm.provinceName" :disabled="true" placeholder="请选择区域">
                <el-option v-for="(item,index) in categoryList" :key="index" :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="topFormItem" label="是否公开" prop="openFlag" style="width: 180px;">
              <el-radio-group v-model="strategyForm.openFlag" :disabled="true">
                <el-radio :value="1">是</el-radio>
                <el-radio :value="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="topFormItem" label="时间段类型" style="width: 100%;">
              <el-checkbox-group v-model="strategyForm.type" :disabled="true" style="display: flex"
                                 @change="dateTypeChange">
                <!--                <el-checkbox :value="0" label="固定"/>-->
                <el-checkbox :value="1" label="月份"/>
                <el-checkbox :value="2" label="日期"/>
              </el-checkbox-group>
            </el-form-item>
            <el-button style="position:absolute;right: 0" type="primary" @click="copyClick">
              <el-icon>
                <Plus/>
              </el-icon>
              复制
            </el-button>
            <el-form-item v-if="strategyForm.type==1" class="topFormItem timeFormItem" label="时间段"
                          required>
              <el-date-picker v-model="strategyForm.monthTimeList[0].monthTime"
                              :disabled="true"
                              format="MM月"
                              placeholder="请选择月份"
                              type="months"
                              value-format="MM"
              />
            </el-form-item>
            <el-form-item v-if="strategyForm.type==2" class="topFormItem timeFormItem" label="时间段"
                          required>
              <el-select ref="elSelectTable" v-model="strategyForm.monthTimeList" :disabled="true"
                         :popper-append-to-body="false" :suffix-icon="Plus"
                         class="timeSelect" multiple popper-class="timeSelectPopper">
              </el-select>
            </el-form-item>
          </div>
          <!--分时策略数据-->
          <div class="strategy">
            <div class="tit">计价方案：</div>
            <div class="strategyBox strategyBox1">
              <div class="titName">分时计费</div>
              <div class="chart"></div>
              <el-table :data="strategyForm.timeList" style="width: 100%;margin: 16px 0 ;">
                <el-table-column label="时间范围" min-width="400">
                  <template #default="scope">
                    <div class="timerBox" style="max-width: 300px">
                      <el-time-picker v-model="scope.row.listTime"
                                      :disabled="true"
                                      end-placeholder="结束时间"
                                      format="HH:mm"
                                      is-range
                                      range-separator="-"
                                      start-placeholder="开始时间"
                                      value-format="HH:mm"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="计价类型" min-width="220">
                  <template #default="scope">
                    <el-select v-model="scope.row.type" :disabled="true" placeholder="请选择">
                      <el-option v-for="(item,index) in powerPriceSelect" :key="index" :label="item.value"
                                 :value="item.key"></el-option>
                      <template #label="{ label, value }">
                         <span v-if="value==1"
                               style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                        <span v-if="value==2"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                        <span v-if="value==3"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                        <span v-if="value==4"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                        <span v-if="value==5"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :data="strategyForm.priceList" style="width: 100%;margin: 16px 0 ;">
                <el-table-column label="计价类型" min-width="220">
                  <template #default="scope">
                    <span v-if="scope.row.type==1"
                          style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                    <span v-if="scope.row.type==2"
                          style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                    <span v-if="scope.row.type==3"
                          style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                    <span v-if="scope.row.type==4"
                          style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                    <span v-if="scope.row.type==5"
                          style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                  </template>
                </el-table-column>
                <el-table-column label="电价" min-width="220">
                  <template #default="scope">
                    <el-input v-model="scope.row.value" :disabled="true">
                      <template #append>元/kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="strategyBox strategyBox2">
              <div class="titName">阶梯计费</div>
              <div class="pricingMethod">
                <span>计价方式：</span>
                <el-radio-group v-model="strategyForm.ladderType	" :disabled="true">
                  <el-radio :value="1">按月用量</el-radio>
                  <el-radio :value="2">按年用量</el-radio>
                </el-radio-group>
              </div>
              <el-table :data="strategyForm.ladderList" style="width: 100%;margin: 16px 0 ;">
                <el-table-column label="档级" min-width="150">
                  <template #default="scope">
                    <span
                        style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #A084FA;color: #fff">第{{
                        scope.$index + 1
                      }}级</span>
                  </template>
                </el-table-column>
                <el-table-column label="下限（>）" min-width="220">
                  <template #default="scope">
                    <el-input v-model="scope.row.downValue" :disabled="true">
                      <template #append>kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="上限（≤）" min-width="220">
                  <template #default="scope">
                    <el-input v-model="scope.row.upValue" :disabled="true">
                      <template #append>kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="电价浮动" min-width="220">
                  <template #default="scope">
                    <span style="margin-right: 5px">+</span>
                    <el-input v-model="scope.row.value" :disabled="true">
                      <template #append>元/kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form>
      </div>
      <!--取消保存操作-->
      <!--      <div class="footer">-->
      <!--        <el-button class="resetButton" @click="goBack">取消</el-button>-->
      <!--        <el-button type="primary" @click="submitClick">确认</el-button>-->
      <!--      </div>-->
    </div>
    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="添加时间段" width="40%">
      <div>
        <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                        range-separator="→"
                        start-placeholder="开始时间"
                        type="daterange"
                        value-format="MM月DD日">
        </el-date-picker>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="addTimerFun">添加</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import './addRateAllocation.less'
import {nextTick, onMounted, reactive, ref, watch} from "vue"
import {Back, Delete, Plus, QuestionFilled, Timer} from '@element-plus/icons-vue'
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router';
import {getConfigProvinceApi} from '@/api/modules/electrovalence'
import qs from "qs";
import * as echarts from "echarts";
import {addPowerPriceListApi, getPowerPriceDetailApi, getTimeShareSelectApi} from "@/api/modules/rate";

let router = useRouter()
// 返回-------------------------------------------------------------------
const goBack = function () {
  router.push('/admin/rateAllocationV2')
}
const copyClick = function () {
  router.push({
    path: '/admin/addRateAllocationV2',
    query: {id: router.currentRoute.value.query.id, type: 'copy'}
  })
}


// 时间列表
let timeList = ref(['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'])


// 时间段操作-------------------------------------------------------------------
// 点击新增时间段
const selectTimeFun = function () {
  dialogVisible.value = true
  dateTime.value = []
}
let dialogVisible = ref(false)  //时间段弹窗
// 点击将时间段添加到选择框内
let dateTime = ref([])
const addTimerFun = function () {
  strategyForm.value.monthTimeList.push(dateTime.value[0] + '-' + dateTime.value[1])
  dialogVisible.value = false
}


// 加载图表
let xAxios = ref([])
let yAxios = ref([])
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 20,
      right: 20,
      bottom: 20,
      top: 40,
    },
    legend: {
      show: true,
    },
    tooltip: {
      show: false
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        },
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      data: xAxios.value
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: yAxios.value,
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}


// 电价表单数据-------------------------------------------------------------------
let strategyForm = ref({
  id: 0,
  provinceName: '',
  openFlag: 0,
  type: [1],
  monthTimeList: [{}],
  name: '',
  timeList: [{
    listTime: [],
    type: '',
  }],
  priceList: [{
    type: 1,
    value: ''
  }, {
    type: 2,
    value: ''
  }, {
    type: 3,
    value: ''
  }, {
    type: 4,
    value: ''
  }, {
    type: 5,
    value: ''
  }],
  ladderType: 1,
  ladderList: [{
    downValue: 0,
    upValue: 0,
    value: '',
  }],
})
// 版本表单验证规则
let strategyRules = ref({
  name: [
    {required: true, message: '请输入名称', trigger: 'blur'},
  ]
})

// 选择省市-------------------------------------------------------------------
let categoryList = ref([])
const getConfigProvinceFun = function () {
  getConfigProvinceApi().then(res => {
    if (res.data.code === 0) {
      categoryList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const dateTypeChange = function () {
  if (strategyForm.value.type == 1) {
    strategyForm.value.monthTimeList = [{}]
  } else {
    strategyForm.value.monthTimeList = []
  }

}


// 新增删除操作-------------------------------------------------------------------
// 新增时段
const addTime = function (idx) {
  strategyForm.value.timeList.push({
    listTime: [],
    type: '',
  })
  // strategyForm.value.timeList[strategyForm.value.timeList.length - 1].listTime[0] = strategyForm.value.timeList[strategyForm.value.timeList.length - 2].listTime[1]
}
// 删除时段
const delTime = function (idx) {
  strategyForm.value.timeList.splice(idx, 1)
}
// 新增计费
const addTime2 = function (idx) {
  strategyForm.value.ladderList.push({
    downValue: '',
    upValue: '',
    value: '',
  })
  strategyForm.value.ladderList[strategyForm.value.ladderList.length - 1].downValue = strategyForm.value.ladderList[strategyForm.value.ladderList.length - 2].upValue
}
// 删除计费
const delTime2 = function (idx) {
  strategyForm.value.ladderList.splice(idx, 1)
}


// 提价表单----------------------------------------------------------------------
// 表单元素
let strategyFormRef = ref()
// 提交表单
const submitClick = () => {
  // 提交表单
  if (strategyFormRef) {
    strategyFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let data = JSON.parse(JSON.stringify(strategyForm.value))
        let list = []
        if (data.type[0] == 1) {  //月份
          data.monthTimeList[0].monthTime = data.monthTimeList[0].monthTime.join(',')
          list = data.monthTimeList
        } else if (data.type[0] == 2) {  //日期
          for (let i = 0; i < data.monthTimeList.length; i++) {
            list.push({
              startTime: data.monthTimeList[i].substring(0, 2) + '-' + data.monthTimeList[i].substring(3, 5) + '-' + data.monthTimeList[i].substring(6, 8),
              endTime: data.monthTimeList[i].substring(10, 12) + '-' + data.monthTimeList[i].substring(13, 15) + '-' + data.monthTimeList[i].substring(16, 18),
            })
          }
        }
        for (let i = 0; i < data.timeList.length; i++) {
          data.timeList[i].startTime = data.timeList[i].listTime[0]
          data.timeList[i].endTime = data.timeList[i].listTime[1]
          delete data.timeList[i].listTime
        }
        data.type = data.type[0]
        data.monthTimeJsonStr = JSON.stringify(list)
        data.timeJsonStr = JSON.stringify(data.timeList)
        data.priceJsonStr = JSON.stringify(data.priceList)
        data.ladderJsonStr = JSON.stringify(data.ladderList)
        delete data.monthTimeList
        delete data.timeList
        delete data.priceList
        delete data.ladderList
        addPowerPriceListApi(qs.stringify(data)).then(res => {
          if (res.data.code === 0) {
            router.push('/admin/rateAllocationV2')
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
};


// 监听方案变化
watch(() => strategyForm.value.timeList, (newVal) => {
  yAxios.value = []
  let list = []
  for (let i = 0; i < newVal.length; i++) {
    list.push({
      type: 'line',
      data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0],
      color: '#3587DA',
      lineStyle: {
        width: 1,
      },
      showSymbol: false,
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, null, 0, 1, [
          {
            offset: 0,
            color: 'rgb(188, 221, 246)'
          },
          {
            offset: 1,
            color: 'rgba(188, 221, 246,0)'
          }
        ])
      }
    })
    if (newVal[i].type == 1) {
      list[i].color = '#F21110'
      list[i].name = '尖'
      list[i].areaStyle = {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(246, 165, 167)'
          },
          {
            offset: 1,
            color: 'rgba(246, 165, 167,0)'
          }
        ])
      }
    } else if (newVal[i].type == 2) {
      list[i].color = '#F57244'
      list[i].name = '峰'
      list[i].areaStyle = {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(247, 216, 200)'
          },
          {
            offset: 1,
            color: 'rgba(247, 216, 200,0)'
          }
        ])
      }
    } else if (newVal[i].type == 3) {
      list[i].color = '#50D5D9'
      list[i].name = '平'
      list[i].areaStyle = {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(202, 242, 246)'
          },
          {
            offset: 1,
            color: 'rgba(202, 242, 246,0)'
          }
        ])
      }
    } else if (newVal[i].type == 4) {
      list[i].color = '#3587DA'
      list[i].name = '谷'
      list[i].areaStyle = {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(189, 222, 247)'
          },
          {
            offset: 1,
            color: 'rgba(189, 222, 247,0)'
          }
        ])
      }
    } else if (newVal[i].type == 5) {
      list[i].color = '#748EE3'
      list[i].name = '深谷'
      list[i].areaStyle = {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(216, 226, 248)'
          },
          {
            offset: 1,
            color: 'rgba(216, 226, 248,0)'
          }
        ])
      }
    }
  }

  let xAxiosArr = []

  // 时间排序
  for (let i = 0; i < newVal.length; i++) {
    if (!xAxiosArr.includes(newVal[i].listTime[0]) && newVal[i].listTime[0]) {
      xAxiosArr.push(newVal[i].listTime[0])
    }
    if (!xAxiosArr.includes(newVal[i].listTime[1]) && newVal[i].listTime[1]) {
      xAxiosArr.push(newVal[i].listTime[1])
    }
    // 时间排序
    xAxiosArr.sort(function (a, b) {
      // 将时间字符串转换为24小时制的分钟数
      var minA = new Date('1970-01-01T' + a + 'Z').getTime();
      var minB = new Date('1970-01-01T' + b + 'Z').getTime();
      return minA - minB;
    });
    xAxios.value = xAxiosArr
  }
  for (let i = 0; i < newVal.length; i++) {
    let startTime = ''
    let endTime = ''
    if (newVal[i].listTime && newVal[i].type) {
      for (let j = 0; j < xAxios.value.length; j++) {
        if (newVal[i].listTime[0] === xAxios.value[j]) {
          startTime = j
        }
        if (newVal[i].listTime[1] === xAxios.value[j]) {
          endTime = j
        }
      }
    }

    for (let j = startTime; j < endTime + 1; j++) {
      if (newVal[i].type == 1) {
        list[i].data[j] = 10
      } else if (newVal[i].type == 2) {
        list[i].data[j] = 8
      } else if (newVal[i].type == 3) {
        list[i].data[j] = 6
      } else if (newVal[i].type == 4) {
        list[i].data[j] = 4
      } else if (newVal[i].type == 5) {
        list[i].data[j] = 2
      }
    }
  }

  yAxios.value = list
  nextTick(() => {
    chartLoad()
  })
}, {deep: true, immediate: true})

let powerPriceSelect = ref()
onMounted(() => {
  // 获取电价类型选项
  getTimeShareSelectApi().then(res => {
    if (res.data.code === 0) {
      powerPriceSelect.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })


  if (router.currentRoute.value.query.id) {
    getPowerPriceDetailApi(qs.stringify({id: router.currentRoute.value.query.id})).then(res => {
      if (res.data.code === 0) {
        strategyForm.value = JSON.parse(JSON.stringify(res.data.result))
        strategyForm.value.type = [strategyForm.value.type]
        // 处理时间段
        if (strategyForm.value.type[0] === 2) {
          let data = []
          for (let i = 0; i < strategyForm.value.monthTimeList.length; i++) {
            data.push(strategyForm.value.monthTimeList[i].startTime.substr(0, 4) + '年' + strategyForm.value.monthTimeList[i].startTime.substr(5, 2) + '月' + strategyForm.value.monthTimeList[i].startTime.substr(8, 2) + '日-' + strategyForm.value.monthTimeList[i].endTime.substr(0, 4) + '年' + strategyForm.value.monthTimeList[i].endTime.substr(5, 2) + '月' + strategyForm.value.monthTimeList[i].endTime.substr(8, 2) + '日')
          }
          strategyForm.value.monthTimeList = data
        } else {
          strategyForm.value.monthTimeList[0].monthTime = strategyForm.value.monthTimeList[0].monthTime.split(',')
        }


        // 处理电价时间段
        for (let i = 0; i < strategyForm.value.timeList.length; i++) {
          strategyForm.value.timeList[i].listTime = [strategyForm.value.timeList[i].startTime, strategyForm.value.timeList[i].endTime]
        }
        // 处理是新建还是编辑
        if (router.currentRoute.value.query.type === 'copy') {
          strategyForm.value.id = 0
        }
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  getConfigProvinceFun()
})

</script>

<style lang="less" scoped>
</style>
