<template>
  <div class="model">
    <div class="title">
      <p>
        <span>用电箱型号管理</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">型号名称</label>
          <el-input
            v-model="queryParams.boxModelName"
            prefix-icon="Search"
            clearable
            placeholder="请输入型号名称"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建用电箱型号
        </el-button>
        <el-table v-loading="loading" :data="modelList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建用电箱型号</p>
            </el-empty>
          </template>
          <el-table-column
            label="型号名称"
            align="center"
            prop="boxModelName"
          />
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="1000px"
      append-to-body
    >
      <el-form ref="modelRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="型号名称" prop="boxModelName">
          <el-input
            v-model="form.boxModelName"
            placeholder="请输入型号名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="添加普通检查项" required>
          <el-table :data="form.powerBoxManualCheckItemDTOList">
            <el-table-column label="名称" align="center" prop="name">
              <template #default="scope">
                <el-form-item
                  :prop="
                    'powerBoxManualCheckItemDTOList.' + scope.$index + '.name'
                  "
                  :rules="[
                    { required: true, message: '请输入名称', trigger: 'blur' },
                  ]"
                >
                  <el-input
                    v-model="scope.row.name"
                    placeholder="请输入名称"
                    clearable
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="类型" align="center" prop="type">
              <template #default="scope">
                <el-form-item
                  :prop="
                    'powerBoxManualCheckItemDTOList.' + scope.$index + '.type'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请选择类型',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.type"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option
                      v-for="dict in sys_type"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="是否必填项"
              align="center"
              prop="isRequired"
            >
              <template #default="scope">
                <el-switch
                  v-model="scope.row.isRequired"
                  :active-value="1"
                  :inactive-value="0"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template #default="scope">
                <el-button
                  type="primary"
                  icon="Plus"
                  @click="
                    form.powerBoxManualCheckItemDTOList.splice(
                      scope.$index + 1,
                      0,
                      {
                        id: null,
                        boxModelId: null,
                        name: '',
                        type: '',
                        isRequired: 0,
                      }
                    )
                  "
                ></el-button>
                <el-button
                  type="danger"
                  icon="Delete"
                  :disabled="form.powerBoxManualCheckItemDTOList.length === 1"
                  @click="
                    form.powerBoxManualCheckItemDTOList.splice(scope.$index, 1)
                  "
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="添加自检项" required>
          <el-table :data="form.powerBoxAutoCheckItemDTOList">
            <el-table-column label="设备" align="center" prop="deviceId">
              <template #default="scope">
                <el-form-item
                  :prop="
                    'powerBoxAutoCheckItemDTOList.' + scope.$index + '.deviceId'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请选择设备',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.deviceId"
                    placeholder="请选择设备"
                    clearable
                    @change="(val) => handleDeviceChange(val, scope.$index)"
                  >
                    <el-option
                      v-for="item in formLists.deviceList"
                      :key="item.id"
                      :label="item.deviceName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="指标" align="center" prop="deviceItemId">
              <template #default="scope">
                <el-form-item
                  :prop="
                    'powerBoxAutoCheckItemDTOList.' +
                    scope.$index +
                    '.deviceItemId'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请选择指标',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.deviceItemId"
                    placeholder="请选择指标"
                    clearable
                  >
                    <el-option
                      v-for="item in form.powerBoxAutoCheckItemDTOList[
                        scope.$index
                      ].itemList"
                      :key="item.id"
                      :label="item.itemName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="条件" align="center" prop="checkCondition">
              <template #default="scope">
                <el-form-item
                  :prop="
                    'powerBoxAutoCheckItemDTOList.' +
                    scope.$index +
                    '.checkCondition'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请选择条件',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.checkCondition"
                    placeholder="请选择条件"
                    clearable
                  >
                    <el-option
                      v-for="dict in sys_check_condition"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="阈值" align="center" prop="checkThreshold">
              <template #default="scope">
                <el-form-item
                  :prop="
                    'powerBoxAutoCheckItemDTOList.' +
                    scope.$index +
                    '.checkThreshold'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请输入阈值',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.checkThreshold"
                    placeholder="请输入阈值"
                    clearable
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template #default="scope">
                <el-button
                  type="primary"
                  icon="Plus"
                  @click="
                    form.powerBoxAutoCheckItemDTOList.splice(
                      scope.$index + 1,
                      0,
                      {
                        id: null,
                        boxModelId: null,
                        deviceId: null,
                        deviceItemId: null,
                        checkCondition: '',
                        checkThreshold: '',
                        itemList: [],
                      }
                    )
                  "
                ></el-button>
                <el-button
                  type="danger"
                  icon="Delete"
                  :disabled="form.powerBoxAutoCheckItemDTOList.length === 1"
                  @click="
                    form.powerBoxAutoCheckItemDTOList.splice(scope.$index, 1)
                  "
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './model.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listModel,
  getModel,
  delModel,
  addModel,
  updateModel,
} from '@/api/modules/model'
import { listDevice, listItem } from '@/api/modules/box'

const modelList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const modelRef = ref(null)

const sys_type = ref([
  {
    label: '文本框',
    value: '1',
  },
  {
    label: '单选框',
    value: '2',
  },
  {
    label: '图片',
    value: '3',
  },
])

const sys_check_condition = ref([
  {
    label: '大于',
    value: '1',
  },
  {
    label: '小于',
    value: '2',
  },
  {
    label: '等于',
    value: '3',
  },
])

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    boxModelName: null,
  },
  rules: {
    boxModelName: [
      { required: true, message: '请输入型号名称', trigger: 'blur' },
    ],
  },
})

const { queryParams, form, rules } = toRefs(data)

const formLists = reactive({
  deviceList: [],
})

/** 查询用电箱型号列表 */
function getList() {
  loading.value = true
  listModel(queryParams.value).then((response) => {
    modelList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    boxModelName: null,
    powerBoxManualCheckItemDTOList: [
      {
        id: null,
        boxModelId: null,
        name: '',
        type: '',
        isRequired: 0,
      },
    ],
    powerBoxAutoCheckItemDTOList: [
      {
        id: null,
        boxModelId: null,
        deviceId: null,
        deviceItemId: null,
        checkCondition: '',
        checkThreshold: '',
        itemList: [],
      },
    ],
  }
  modelRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    boxModelName: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listDevice().then((response) => {
    formLists.deviceList = response.data.result
    open.value = true
    title.value = '新建用电箱型号'
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listDevice().then((response) => {
    formLists.deviceList = response.data.result
    getModel(row.id).then((response) => {
      form.value = response.data.result

      const promises = form.value.powerBoxAutoCheckItemDTOList.map(
        async (item) => {
          const response = await listItem(item.deviceId)
          item.itemList = response.data.result
        }
      )

      Promise.all(promises).then(() => {
        open.value = true
        title.value = '修改用电箱型号'
      })
    })
  })
}

/** 提交按钮 */
function submitForm() {
  modelRef.value.validate((valid) => {
    if (valid) {
      form.value.powerBoxAutoCheckItemDTOList.forEach((item) => {
        delete item.itemList
      })

      if (form.value.id != null) {
        updateModel(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addModel(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除用电箱型号',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此用电箱型号，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delModel(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}

const handleDeviceChange = (val, index) => {
  form.value.powerBoxAutoCheckItemDTOList[index].itemList = []
  form.value.powerBoxAutoCheckItemDTOList[index].deviceItemId = null
  if (!val) {
    return
  }
  listItem(val).then((response) => {
    form.value.powerBoxAutoCheckItemDTOList[index].itemList =
      response.data.result
  })
}
</script>
